import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import {useAppSelector, usePrevious} from "../../../../hooks.ts";
import Glance from '../../../Glance';
import Filters from '../Filters';

const emptyData = {
	"clicks": 0,
	"conversions": 0,
	"revenue": 0,
	"spend": 0,
	"profit": 0,
	"profit_percent": 0,
}

const dataTypes = {
	"clicks": "number",
	"conversions": "number",
	"revenue": "currency",
	"spend": "currency",
	"profit": "currency",
	"profit_percent": "percent",
}

const dataNames = {
	"clicks": "Clicks",
	"conversions": "Conversions",
	"revenue": "Revenue",
	"spend": "Spend",
	"profit": "Profit",
	"profit_percent": "Profit %",
}

const dataKeys = ['clicks', 'conversions', 'revenue', 'spend', 'profit', 'profit_percent'];

export default function ManageGlance() {
  const {tableRows} = useAppSelector((state) => state.main);
	const [data, setData] = useState<{ [index: string]: number }>(emptyData);
  const previousRows = usePrevious(tableRows);

	const calculateGlance = async () => {
    const sum = tableRows.reduce((acc: object, row: object) => {
      dataKeys.forEach((key: string) => {
        acc[key] = (acc[key] || 0) + Number(row[key]);
      });
      return acc;
    }, {});
    if (sum.profit && sum.revenue) {
      sum.profit_percent = (sum.profit / sum.revenue) * 100;
    } else if (sum.profit < 0 && sum.revenue === 0) {
      sum.profit_percent = -100;
    } else {
      sum.profit_percent = 0;
    }
    setData(sum);
	};

	useEffect(() => {
    if (tableRows && tableRows.length && !isEqual(tableRows, previousRows)) {
      calculateGlance()
        .catch((error) => console.error(error));
    }
	}, [tableRows, previousRows]);

	useEffect(() => {
    return () => setData(emptyData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex w-full flex-col items-center justify-center rounded-md border border-solid border-black/80 bg-white shadow-md">
			<Filters />
      <Glance data={data} dataTypes={dataTypes} dataNames={dataNames} />
		</div>
	)
}
