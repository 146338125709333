import {validateTaboolaSettings} from "./Validation/TaboolaDetails.tsx";
import {validateLinks} from "./Validation/Links.tsx";
import {validateFireAndForget} from "./Validation/FireAndForget.tsx";
import {validateAds} from "./Validation/Ads.tsx";

export interface ValidateStepProps {
	fields: object,
}

export default function validateStep(currentStep: number, values: object): ValidateStepProps {
  let errors = {fields: {}};
  switch (currentStep) {
    case 1:
      errors = validateTaboolaSettings(values.settings);
      break;
    case 2:
      errors = validateLinks({
        link: values.link,
        presellLinks: values.presellLinks,
        landingPageLinks: values.landingPageLinks,
        maximusLinks: values.settings.maximusLinks,
        advancedTracking: values.advancedTracking,
				campaignSettings: values.settings,
      });
      break;
    case 3:
      errors = validateFireAndForget(values.fireAndForget, (values.settings.bidStrategy === '1' || values.settings.bidStrategy === '2'));
      break;
    case 4:
      break;
    case 5:
      errors = validateAds(values.ads);
      break;
    default:
      break;
  }
  return errors;
}
