import type { GridColDef } from "@mui/x-data-grid-premium";

import {estimatedFieldsMap} from "../constants.ts";

type ColumnVisibilityDict = { [key: string]: boolean }

const includeEstimatedFieldsForOrder = (columns: GridColDef[]) => {
	return columns.map((column) => {
		if (estimatedFieldsMap[column.field]) {
			return {
				...column,
				field: estimatedFieldsMap[column.field],
			};
		}
		return column;
	});
}

const replaceEstimatedFieldsWithOriginal = (columns: GridColDef[]) => {
	return columns.map((column) => {
		const estimateField = Object.entries(estimatedFieldsMap).find(([, value]) => value === column.field);
		if (estimateField) {
			return {
				...column,
				field: estimateField[0],
			};
		}
		return column;
	});
}

const includeEstimatedFieldsForVisibility = (columns: ColumnVisibilityDict) => {
	const newColumns: ColumnVisibilityDict = {};
	Object.entries(columns).forEach(([key, value]) => {
		const estimateField = Object.entries(estimatedFieldsMap).find(([estimateKey,]) => estimateKey === key);
		if (estimateField) {
			newColumns[estimateField[1]] = value;
		} else {
			newColumns[key] = value;
		}
	});
	return newColumns;
}

const replaceEstimatedFieldsWithOriginalVisibility = (columns: ColumnVisibilityDict) => {
	const newColumns: ColumnVisibilityDict = {};
	Object.entries(columns).forEach(([key, value]) => {
		const estimateField = Object.entries(estimatedFieldsMap).find(([, val]) => val === key);
		if (estimateField) {
			newColumns[estimateField[0]] = value;
		} else {
			newColumns[key] = value;
		}
	});
	return newColumns;
}


export const visibleColumnsStorageName = (tableName: string): string => {
	return `${tableName}_columns_hidden`;
}

export const columnsStorageName = (tableName: string): string => {
	return `${tableName}_columns`;
}

export const onColumnOrderChange = (newColumns: GridColDef[], tableName: string) => {
	localStorage.setItem(
		columnsStorageName(tableName),
		JSON.stringify(
			replaceEstimatedFieldsWithOriginal(newColumns)
		)
	);
}

export const onColumnVisibilityChange = (visibleColumns: GridColDef[], tableName: string, defaultColumns: GridColDef[]) => {
	const columns: ColumnVisibilityDict = {};
	defaultColumns.forEach((defaultColumn) => {
		const visibility = visibleColumns.find(({field}) => field === defaultColumn.field);
		columns[defaultColumn.field] = !!visibility;
	});
	localStorage.setItem(
		visibleColumnsStorageName(tableName),
		JSON.stringify(
			replaceEstimatedFieldsWithOriginalVisibility(columns)
		)
	);
}

export function columnReorderHelper(defaultColumns: GridColDef[], tableName: string, estimates = false): GridColDef[] {
	const columns: GridColDef[] = [];

	const columnsStorage = localStorage.getItem(columnsStorageName(tableName));
	try {
		if (columnsStorage) {
			let columnsOrder = JSON.parse(columnsStorage) as GridColDef[];
			if (columnsOrder && columnsOrder.length > 0) {
				if (estimates) {
					columnsOrder = includeEstimatedFieldsForOrder(columnsOrder);
				}
				const storedColumnFields = columnsOrder.map((column) => column.field).sort();
				const defaultColumnFields = defaultColumns.map((column) => column.field).sort();
				const fieldsDoMatch = storedColumnFields.every((field, index) => field === defaultColumnFields[index]);

				if (fieldsDoMatch) {
					columnsOrder.forEach((column) => {
						const defaultColumn = defaultColumns.find((col) => col.field === column.field);
						if (defaultColumn) {
							columns.push(defaultColumn);
						}
					});
					return columns;
				} else {
					localStorage.removeItem(columnsStorageName(tableName));
				}
			}
		}
		columns.push(...defaultColumns);
		return columns;
	} catch (e) {
		return defaultColumns;
	}
}

const DEFAULT_COLUMNS_VISIBILITY: { [report: string]: ColumnVisibilityDict } = {
	"Images": {
		description: false,
		cta: false,
		videoThumbnail: false,
		presell_ctr: false,
	},
}

	export function columnVisibilityHelper(defaultColumns: GridColDef[], tableName: string, estimates = false): ColumnVisibilityDict {
		const columnsStorage = localStorage.getItem(visibleColumnsStorageName(tableName));
		try {
			if (columnsStorage) {
				let columnVisibility = JSON.parse(columnsStorage);
				if (columnVisibility && typeof columnVisibility === 'object' && Object.keys(columnVisibility).length > 0) {
					if (estimates) {
						columnVisibility = includeEstimatedFieldsForVisibility(columnVisibility);
					}
					const defaultColumnFields = defaultColumns.map((column) => column.field);
					const columnVisibilityFields = Object.keys(columnVisibility);
					defaultColumnFields.forEach((field) => {
						if (!columnVisibilityFields.includes(field)) {
							columnVisibility[field] = true;
						}
					});
					return columnVisibility;
				}
			}
			return DEFAULT_COLUMNS_VISIBILITY[tableName] || {};
		} catch (e) {
			return DEFAULT_COLUMNS_VISIBILITY[tableName] || {};
		}
}