import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridCellModes, GridRowId } from '@mui/x-data-grid-premium';
import Warning from '../../../../Icons/Warning.tsx';
import Toggle from '../../../FormComponents/Toggle.tsx';
import Check from '../../../../Icons/Check.tsx';
import Close from '../../../../Icons/Close.tsx';
import Edit from '../../../../Icons/Edit.tsx';

export const StatusIcon = ({ status }) => (
  <Tooltip title={status} placement="top">
    <Warning
      className={`manage-name-status ${status.toLowerCase().indexOf('pending') === 0 ? 'pending' : 'rejected'}`}/>
  </Tooltip>
);

export const CampaignNameCell = (params) => (
  <div className="manage-report-campaign-name">
    <Link className="manage-name-link" to={`/campaign_details/${params?.value}`} state={{campaignID: params?.row?.campaign_id}}>{params.value}</Link>
    {(params.row.text_status && !['RUNNING', 'DEPLETED_MONTHLY', 'PAUSED'].includes(params.row.text_status)) &&
      <StatusIcon status={params.row.textStatus} />}
  </div>
);

const editCellHandlers = (cellModesModel, setCellModesModel) => {
  const handleEditClick = (id: GridRowId) => () => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { budget: { mode: GridCellModes.Edit } },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { budget: { mode: GridCellModes.View } },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { budget: { mode: GridCellModes.View, ignoreModifications: true } },
    });
  };

  return { handleEditClick, handleSaveClick, handleCancelClick };
}

export const BudgetActions = ({ id, row }, cellModesModel, setCellModesModel, submittingBudgets) => {
  if (row.budget === undefined) {
    return '';
  }
  const { handleEditClick, handleSaveClick, handleCancelClick } = editCellHandlers(cellModesModel, setCellModesModel);
  const isInEditMode = cellModesModel[id]?.budget?.mode === GridCellModes.Edit;

  if (isInEditMode) {
    return [
      <GridActionsCellItem
        icon={<Check className="manage-edit-icon" />}
        label="Save"
        sx={{ color: 'primary.main' }}
        onClick={handleSaveClick(id)}
        key={`save-edit-budget-${id}`}
      />,
      <GridActionsCellItem
        icon={<Close className="manage-edit-icon" />}
        label="Cancel"
        className="textPrimary"
        onClick={handleCancelClick(id)}
        color="inherit"
        key={`cancel-edit-budget-${id}`}
      />,
    ];
  }

  return [
    <GridActionsCellItem
      icon={<Edit className="manage-edit-icon" />}
      label="Edit"
      className="textPrimary"
      onClick={handleEditClick(id)}
      color="inherit"
      disabled={submittingBudgets.includes(id)}
      key={`edit-budget-${id}`}
    />,
  ];
}

export const StatusToggle = (params: object, name: string, changeStatus, disabled = false) => {
  const campaignIsRejected = params.row.text_status === 'REJECTED';
  return params.value !== undefined ? (
    <Toggle
      name={`${name}-${params.row.id}`}
      onChange={() => changeStatus(params.row.id, !params.value, params.row)}
      value={(!(campaignIsRejected || !params.value))}
      disabled={campaignIsRejected || disabled}
      tooltipTitle={campaignIsRejected ?
        'Taboola prevents users from editing the status of rejected campaigns. If you would like to hide this campaign in Maximus, you can archive it' : ''}
    />) : '';
}
