import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Button } from '@mui/material';
import { format } from 'date-fns';
import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import { hoursComparator } from '../../../../../utils/tables-utils.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';

const commonColumnsList = [
	'impressions',
	'clicks',
	'spend',
	'revenue',
	'ctr',
	'conversions',
	'cvr',
	'cpa',
	'epc',
	'profit',
	'profit_percent',
];

export default function ByDate() {
	const [hoursReport, toggleHoursReport] = useState<boolean>(false);
	const [selectedDay, setSelectedDay] = useState<string>('');

	const renderDateButton = (params) => (
		<Button
			onClick={() => {
				setSelectedDay(params.value);
				toggleHoursReport(true);
			}}
		>
			{params.row.date}
		</Button>);

	const columns = {
		days: [
			{
				field: 'date', headerName: 'Date', width: 120, type: 'date',
				renderCell: renderDateButton,
				valueGetter:  (_value, row) => row && row.date ? new Date(row.date) : '',
			},
			...commonColumns(commonColumnsList),
		] satisfies GridColDef[],
		hours: [
			{
				field: 'hour', headerName: 'Hour', width: 100, sortComparator: hoursComparator,
			},
			...commonColumns(commonColumnsList),
		] satisfies GridColDef[],
	};

	return (
		<div className="campaign-details-report">
			{hoursReport &&
				<Button className="campaign-details-report-back-button" onClick={() => toggleHoursReport(false)}>
					Back To By Date Report
				</Button>}
			<ReportTable
				columns={columns[hoursReport ? 'hours' : 'days']}
				tableName={hoursReport ? `By Hour For ${format(selectedDay, 'MM/dd/yyyy')}` : 'By Date'}
				additionalRequestParams={
					hoursReport ? {
						startDate: format(selectedDay, 'yyyy-MM-dd'),
						endDate: format(selectedDay, 'yyyy-MM-dd'),
						dateRange: 'custom',
					} : {}
				}
				endpoint={hoursReport ? 'api/v1/campaign/reporting/byDate/byHour' : 'api/v1/campaign/reporting/byDate'}
			/>
		</div>
	);
}
