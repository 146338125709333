import {useEffect, useState, Dispatch, SetStateAction} from 'react';
import Upload from '../../../../../Icons/Upload';
import {networkRequestMultipart} from '../../../../../utils/networkRequest.ts';
import {CircularProgress} from "@mui/material";
import {openSnackbar} from "../../../../../Redux/Slices/main";
import {useAppDispatch} from "../../../../../hooks";

function getFileDimensions(src, file, index, callback, thumbnailUrl = '') {
	if (file.type.split('/')[0] === 'image') {
		const fileName = `[IMG] ${file.name.split('.')[0]}`;
		callback(src, fileName, 0, 0, index, file.type.match('image/gif') ? 'video' : 'image');
	} else {
		const video = document.createElement('video');
		video.addEventListener('loadedmetadata', function () {
			const height = this.videoHeight;
			const width = this.videoWidth;
			const fileName = `[VID] ${file.name.split('.')[0]}`;
			callback(src, fileName, height, width, index, 'video', thumbnailUrl);
		}, false);
		video.src = src;
	}
}

function getVideoCover(file, seekTo = 0.0) {
	return new Promise((resolve, reject) => {
		const videoPlayer = document.createElement('video');
		videoPlayer.setAttribute('crossorigin', 'anonymous');
		videoPlayer.setAttribute('src', URL.createObjectURL(file));
		videoPlayer.load();
		videoPlayer.addEventListener('error', () => {
			// eslint-disable-next-line prefer-promise-reject-errors
			reject('Error when loading video file. Please try again.');
		});
		videoPlayer.addEventListener('loadedmetadata', () => {
			if (videoPlayer.duration < seekTo) {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Video is too short. Please upload another one.');
				return;
			}
			setTimeout(() => {
				videoPlayer.currentTime = seekTo;
			}, 200);
			videoPlayer.addEventListener('seeked', () => {
				const canvas = document.createElement('canvas');
				canvas.width = videoPlayer.videoWidth;
				canvas.height = videoPlayer.videoHeight;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
				ctx.canvas.toBlob(
					(blob) => {
						resolve(blob);
					},
					'image/jpeg',
					1,
				);
			});
		});
	});
}

const validateFile = (file) => {
	const type = file.type.split('/')[0];
	if (type === 'video') {
		if (file.type !== 'video/mp4' && file.type !== 'video/quicktime') {
			return 'Valid video formats are .mp4 and .mov. Please upload another video.'
		}
	}
	return '';
};

export default function UploadAds(
	{
		images,
		changeImages
	}: { images: Array<object>, changeImages: Dispatch<SetStateAction<any>> }): JSX.Element {
	const [newImages, setNewImages] = useState([]);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const dropArea = document.getElementById("drop-area");

		function preventDefaults(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
			dropArea.addEventListener(eventName, preventDefaults, false);
		});
		dropArea.addEventListener('drop', handleDrop, false);
		dropArea.addEventListener('click', handleClick, false);
		// eslint-disable-next-line
	}, []);


	function handleClick(event) {
		event.preventDefault();
		if (loading) {
			return false;
		}
		const hiddenInput = document.getElementById("fileInput");
		return hiddenInput.click();
	}

	function handleDrop(event) {
		if (loading) {
			return false;
		}
		const {dataTransfer} = event;
		const {files} = dataTransfer;
		return handleUpload(files);
	}

	const getEndpoint = (file) => {
		if (file.type.match('video.*') || file.type.match('image/gif')) {
			return 'api/v1/campaign/fileUpload/video';
		}
		return 'api/v1/campaign/fileUpload/image';
	}
	const uploadFile = async (file) => {
		return networkRequestMultipart(
			getEndpoint(file),
			{
				file,
				network: 1
			},
		)
			.then((response) => response.json())
			.catch(() => ({url: ''}));
	}

	const validateAds = (fileArray) => {
		let validAd = true;
		let validationMessage = '';
		if (loading) {
			validAd = false;
		}
		fileArray.forEach((file) => {
			validationMessage = validateFile(file);
			if (validationMessage) {
				validAd = false;
			}
		});
		if (!validAd) {
			dispatch(openSnackbar(
				{
					children: validationMessage || 'Something went wrong while uploading one or multiple ads. Please check your ads and try again',
					severity: 'error'
				}
			));
		}
		return validAd;
	};

	const createThumbnail = async (file, dimensions) => {
		const cover = await getVideoCover(file, 0.1);
		const newFile = new File([cover], 'videoThumbnail.jpg', { type: 'image/jpeg' });
		return await uploadFile(newFile);
	}

	const handleUpload = async (files) => {
		const fileArray = Object.values(files);
		const validAd = validateAds(fileArray);
		if (!validAd) {
			return false;
		}
		setLoading(true);
		const promises = fileArray.map(async (file) => {
			const response = await uploadFile(file);
			return {data: response, file};
		});
		return handlePromises(promises);
	};

	const handlePromises = async (promises) => {
		Promise.all(promises)
			.then(async (responses) => {
				setUploadedImages(responses);
				for await (const [index, { data, file }] of responses.entries()) {
					if (data.url) {
						let thumbnailUrl = '';
						if (file.type.match('video.*')) {
							try {
								const dataThumbnail = await createThumbnail(file);
								thumbnailUrl = dataThumbnail.url || '';
							} catch (e) {
								dispatch(openSnackbar(
									{
										children: e || 'Something went wrong while uploading one or multiple ads. Please check your ads and try  again',
										severity: 'error'
									}
								));
								continue;
							}
						}
						getFileDimensions(data.url, file, index, handleResponse, thumbnailUrl);
						continue;
					}
					setUploadedImages((prevState) => prevState.filter((item) => item.file.name !== file.name));
					dispatch(openSnackbar(
						{
							children: data.message || 'Something went wrong while uploading one or multiple ads. Please check your ads and try  again',
							severity: 'error'
						}
					));
				}
				setLoading(false);
			})
	};

	const handleResponse = async (src, fileName, height, width, index, fileType, thumbnail = '') => {
		const newImage = {
			value: src,
			width,
			height,
			fileType,
			thumbnail,
		};
		setNewImages((prevState) => [...prevState, newImage]);
	};

	useEffect(() => {
		if (newImages.length > 0 && uploadedImages.length > 0 && newImages.length === uploadedImages.length) {
			changeImages([...images, ...newImages]);
			setNewImages([]);
			setUploadedImages([]);
		}
	}, [newImages, uploadedImages]);

	return (
		<div className="add-campaign-upload-ads-container">
			<input
				value=''
				style={{display: 'none'}}
				type="file"
				id="fileInput"
				multiple
				accept="video/*, image/png, image/jpg, image/gif, image/webp"
				onChange={(event) => handleUpload(event.target.files)}
			/>
			<div className="add-ad-drag-drop-container" id="drop-area">
				<div className="add-add-drop-icon-button">
					{loading
						? <CircularProgress/>
						: <Upload/>
					}
				</div>
				<div className="add-add-drop-title">
					Upload Images or Videos
				</div>
			</div>
		</div>
	);
}
