import { useState } from 'react';
import { Breadcrumbs, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { commonColumns } from '../../../../../utils/commonColumns';
import { formatPercent } from '../../../../../utils/formatNumbers.ts';
import List from '../../../../../Icons/List';
import Lock from '../../../../../Icons/Lock';
import LockOpen from '../../../../../Icons/LockOpen';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import Toggle from '../../../../FormComponents/Toggle.tsx';
import {CurrencyComparator, editTableRows} from '../../../../../utils/tables-utils.tsx';
import {closeSnackbar, openSnackbar, updateMainStore, updateUserSettings} from "../../../../../Redux/Slices/main";
import networkRequest from "../../../../../utils/networkRequest";
import { getOttoPresellName } from './Presells.tsx';
import { ImageCell } from './shared/ImageCell.tsx';

export default function ImageSitePresell() {
  const dispatch = useAppDispatch();
  const { isOtto, estimatedSpends } = useAppSelector((state) => state.campaignDetails);
  const { redirectOnRouting } = useAppSelector((state) => state.main.userSettings);
  const [selectedReport, setSelectedReport] = useState('images');
  const [selectedImage, setSelectedImage] = useState<string>();
  const [selectedSite, setSelectedSite] = useState<{ id: string, name: string }>();
  const [routingLoading, setRoutingLoading] = useState(false);

  const changeReportButton = (params: object, report: string) => params.row.id ? (
    <IconButton
      onClick={() => {
				console.log('params', params)
        if (report === 'sites') {
          setSelectedImage(params.row.ad_id);
        } else {
          setSelectedSite({ id: params.row.site_id, name: params.row.site_name});
        }
        setSelectedReport(report);
      }}
    >
      <List className="report-list-icon" />
    </IconButton>) : '';

  const routingIcon = (params: {row: {routing: boolean}}) => (
    params.row.routing
      ? (<div className="status-circle-container">
          <Tooltip title="This combination has Presell routing settings">
            <div style={{ display: 'flex' }}><Lock className="blue-lock" /></div>
          </Tooltip>
        </div>)
      : null
  );

  const statusCircle = (params: object) => params.row.id ? (
    <div className="status-circle-container">
      <Tooltip title={params.row.status === true ? 'Active' : 'Paused'}>
        <div className={`status-circle-${params.row.status === true ? 'active' : 'inactive'}`} />
      </Tooltip>
    </div>
	) : '';

  const columns = {
    images: [
      {
        field: 'sites', headerName: 'Sites', width: 90, renderCell: (params) => changeReportButton(params, 'sites'),
      },
      {
        field: 'status', headerName: 'Status', width: 90, renderCell: (params) => statusCircle(params),
      },
      {
        field: 'routing', headerName: 'Routing', width: 90, renderCell: routingIcon,
      },
      {
        field: 'image', headerName: 'Image', width: 124, renderCell: (params) => params.row.id ? <ImageCell url={params.value} /> : '',
      },
      {
        field: 'headline', headerName: 'Headline', width: 250,
      },
      ...commonColumns(
        [
          'visits',
          'clicks',
          'presell_ctr',
          'conversions',
          'epc',
          'spend',
          'revenue',
          'profit',
          'profit_percent',
          'cvr',
        ],
      ),
      {
        field: 'network_ctr',
        headerName: 'Network CTR',
        width: 150,
        type: 'number',
        renderCell: (params) => formatPercent(params.value),
        sortComparator: CurrencyComparator,
      },
    ],
    sites: [
      {
        field: 'presells', headerName: 'Presells', width: 100, renderCell: (params) => changeReportButton(params, 'presells'),
      },
      {
        field: 'site_name', headerName: 'Site Name', width: 200, renderCell: (params) => ( <div>{params.value}</div> ),
      },
      {
        field: 'routing', headerName: 'Routing', width: 90, renderCell: routingIcon,
      },
      ...commonColumns(
        [
          'visits',
          'clicks',
          'conversions',
          'spend',
          'profit',
          'profit_percent',
          'revenue',
          'cpa',
          'cvr',
          'average_cpc',
        ]
      ),
    ],
    presells: [
			{
				field: 'routing',
        headerName: 'Routing',
        width: 90,
        renderCell: params => (
          <RoutingButton
            row={params.row}
            imageID={selectedImage!}
            site={selectedSite!}
            disabled={routingLoading}
            onSubmit={() => setRoutingLoading(true)}
            onSuccess={onRedirectOnRouting}
          />
        ),
			},
      {
        field: 'presell_url',
        headerName: 'Presell URL',
        width: 200,
        renderCell: (params) => (
          <a target="_blank" className="manage-name-link" href={params.value}>
            {isOtto ? getOttoPresellName(params.value) : params.value}
          </a>),
      },
      ...commonColumns(
        [
          'visits',
          'clicks',
          'presell_ctr',
          'conversions',
          'epc',
          'spend',
          'revenue',
          'profit',
          'profit_percent',
          'cvr',
        ],
      ),
    ],
  } satisfies Record<string, GridColDef[]>;

  const aggregationModel = {
    sites: 'blankColumn',
    status: 'blankColumn',
    routing: 'blankColumn',
    image: 'blankColumn',
    headline: 'blankColumn',
    presells: 'blankColumn',
    site_name: 'blankColumn',
    presell_url: 'blankColumn',
    visits: 'sum',
    clicks: 'sum',
    conversions: 'sum',
    presell_ctr: 'avg',
    epc: 'avg',
    spend: 'sum',
    revenue: 'sum',
    profit: 'sum',
    profit_percent: 'profitPercent',
    cvr: 'avg',
    cpa: 'avg',
    average_cpc: 'avg',
    network_ctr: 'avg',
    maximusProfit: 'sum',
    maximusProfitPercent: 'maximusProfitPercent',
    maximusClicks: 'sum',
    estimatedSpend: 'sum',
    maximusRevenue: 'sum',
    maximusConversions: 'sum',
  };

  const additionalParams = {};
  if (selectedReport === 'sites') {
    additionalParams['adID'] = selectedImage;
  } else if (selectedReport === 'presells') {
		additionalParams['adID'] = selectedImage;
    additionalParams['siteID'] = selectedSite?.id;
  }

  const breadcrumbs = [
    {
      label: selectedImage ? `Image: ${selectedImage}` : 'Images',
      tooltip: 'Back to images',
      isCurrent: selectedReport === 'images',
      onClick: () => {
        setSelectedReport('images');
        setSelectedImage(undefined);
        setSelectedSite(undefined);
      }
    },
    {
      label: selectedSite?.name || 'Sites',
      tooltip: 'Back to sites',
      isCurrent: selectedReport === 'sites',
      disabled: !selectedImage,
      onClick: () => {
        setSelectedReport('sites');
        setSelectedSite(undefined);
      }
    },
    {
      label: 'Presells',
      isCurrent: selectedReport === 'presells',
      disabled: !selectedSite?.name
    },
  ]

  function onRedirectOnRouting(row: Record<string, any>) {
    setRoutingLoading(false)
    if (!redirectOnRouting) return
    setSelectedReport('sites');
    setSelectedSite(undefined);
		dispatch(
			openSnackbar({
				severity: 'success',
				children: (
					<div style={{ padding: 4 }}>
						<h4 style={{ margin: '0 0 8px' }}>Successfully updated routing for "{row.site_name}"</h4>
            <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
              <span>Redirected back to the Site level</span>
              <Button variant="outlined" size="small" color='success' onClick={() => {
                setSelectedSite({ id: row.site_id, name: row.site_name })
                setSelectedReport('presells')
                dispatch(closeSnackbar())
              }}>
                Back to Presell level
              </Button>
            </div>
					</div>
				),
			})
		)
  }

  return (
    <div className="campaign-details-report">
      <header className="campaign-details-report-header">
        <Breadcrumbs sx={{ margin: 0 }}>
          {breadcrumbs.map(({ label, tooltip, isCurrent, disabled, onClick }, ix) => (
            <Tooltip key={ix} title={tooltip}>
              <Button
                sx={{ textTransform: 'capitalize', margin: '0 -4px', padding: '0 8px', minWidth: 0, ...(isCurrent && { color: '#000e !important' })}}
                disabled={disabled || isCurrent}
                onClick={onClick}
              >
                {label}
              </Button>
            </Tooltip>
          ))}
        </Breadcrumbs>

        {selectedReport === 'presells' && (
          <Toggle
            label="Redirect to Site level on Routing"
            name="redirect-on-routing"
            value={redirectOnRouting}
            onChange={() => {
              dispatch(updateUserSettings({ key: 'redirectOnRouting', value: !redirectOnRouting }))
              localStorage.setItem('userSettings_redirectOnRouting', (!redirectOnRouting).toString())
            }}
          />
        )}
      </header>

			{!estimatedSpends ? (
				<div className="states-report-disclaimer w-full text-center font-bold">
					* This report is built <span className="underline">using estimates</span> so it may not match campaigns that
					use network data *
				</div>
			) : ''}
			<ReportTable
				endpoint="api/v1/campaign/reporting/imagesitepresell"
        columns={columns[selectedReport]}
        tableName="Image/Site/Presell"
        additionalRequestParams={additionalParams}
        aggregationModel={aggregationModel}
      />
    </div>
  );
}

type RoutingButtonProps = {
  row: { id: number, routing: boolean, presell_url: string }
  site: { id: string, name: string }
  imageID: string
  disabled: boolean
  onSubmit: () => void
  onSuccess: (row: Record<string, any>) => void
}

const RoutingButton = ({ row, imageID, site, disabled, onSubmit, onSuccess }: RoutingButtonProps) => {
  const { id, routing, presell_url } = row

	const tableRows = useAppSelector(state => state.main.tableRows)
	const { campaignID, network } = useAppSelector(state => state.campaignDetails)
	const dispatch = useAppDispatch()

	const [submitting, setSubmitting] = useState(false)

  const tooltip = routing
    ? `Click here to remove the routing lock from this presell`
    : `Click here to route "${site.name}" to this presell`

	const routePresell = () => {
    if (submitting) return
    onSubmit()
		setSubmitting(true)
		networkRequest('api/v1/campaign/routing/update', {
			campaignID,
			lockRoute: !routing,
			network,
			imageID,
			siteID: site.id,
			presellUrl: presell_url,
		})
			.then(response => response.json())
			.then(response => {
        if (response?.success) {
					dispatch(updateMainStore({ key: 'tableRows', value: editTableRows(tableRows, id, 'routing', !routing) }))
          onSuccess(row)
				} else {
					dispatch(openSnackbar({ children: response?.message || 'Something went wrong. Please try again.', severity: 'error' }))
				}
			})
      .catch(() => {
        dispatch(openSnackbar({ children: 'Something went wrong. Please try again.', severity: 'error' }));
      })
			.finally(() => setSubmitting(false))
	}

  if (!routing && !presell_url) return null

  return (
		<Tooltip title={tooltip}>
      <IconButton style={(disabled && !submitting) ? { opacity: 0.5 } : undefined} disabled={disabled} onClick={routePresell}>
        {submitting ? (
          <CircularProgress size={25} color="inherit" />
        ) : row.routing ? (
          <Lock className="blue-lock" />
        ) : (
          <LockOpen className="gray-lock" />
        )}
      </IconButton>
    </Tooltip>
	)
};
