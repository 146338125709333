import {useEffect, useState} from 'react';
import {CircularProgress, IconButton, Tooltip} from '@mui/material';
import Input from '../../../../AddCampaign/Fields/Input.tsx';
import networkRequest from '../../../../../../utils/networkRequest.ts';
import {openSnackbar, updateMainStore} from '../../../../../../Redux/Slices/main.ts';
import type {AppDispatch} from '../../../../../../Redux/reduxStore.ts';
import {editTableRows} from '../../../../../../utils/tables-utils.tsx';
import Edit from '../../../../../../Icons/Edit.tsx';
import Check from '../../../../../../Icons/Check.tsx';
import Minus from '../../../../../../Icons/Minus.tsx';
import Close from "../../../../../../Icons/Close";
import {storeData} from "./Sites";

const modifiedCPCValidation = (modCPC: string, cpc: number) => {
	let lowValue = 0.01 * cpc;
	if (lowValue < 0.01) {
		lowValue = 0.01;
	}
	const maxValue = cpc * 2;
	if (Number(modCPC) < lowValue || Number(modCPC) > maxValue) {
		return `Modified CPC needs to be between ${lowValue} and ${maxValue}.`
	}
	return '';
};

export default function SiteNameCell (params: never, {
	campaignID,
	network,
	tableRows,
	hasSiteBids
}: storeData, dispatch: AppDispatch) {
	const [modifiedCpc, setModifiedCpc] = useState(params.row.modifiedCpc);
	const [errorModifiedCpc, setErrorModifiedCpc] = useState<string>('');
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [showEditButton, setShowEditButton] = useState<boolean>(true);

	useEffect(() => setModifiedCpc(params.row.modifiedCpc), [params.row.modifiedCpc]);
	const editModifiedCPC = (edit: boolean) => {
		if (edit) {
			const validationMessage = modifiedCPCValidation(modifiedCpc, params.row.cpc);
			if (validationMessage) {
				setErrorModifiedCpc(validationMessage);
				return;
			}
		}
		setErrorModifiedCpc('');
		setSubmitting(true);
		networkRequest('api/v1/campaign/siteBids/update', {
			campaignID,
			network,
			siteID: params.row.siteID,
			cpc: params.row.cpc,
			modifiedCpc: edit ? modifiedCpc : params.row.cpc,
		}, 'POST')
			.then((response) => response.json())
			.then((response) => {
					if (response && response.success) {
						dispatch(updateMainStore({
							key: 'tableRows',
							value: editTableRows(tableRows, params.row.siteID, 'modifiedCpc', edit ? modifiedCpc : params.row.cpc, 'siteID')
						}));
						dispatch(openSnackbar({
							children: 'Bid was updated successfully.',
							severity: 'success',
						}));
						setShowEditButton(true);
						if (!edit) {
							setModifiedCpc(params.row.cpc);
						}
					} else {
						dispatch(openSnackbar({
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error',
						}));
					}
					setSubmitting(false);
				}
			);
	}

	return params.value === undefined ? '' : (
		<div className="cell-site-bid">
			<div className={`manage-name-link ${params.row.blocked ? 'site-blocked' : ''}`}>{params.value}</div>
			{hasSiteBids && !params.row.blocked &&
        <div className="cell-site-bid-form">
          <Input
            onChange={({target: {value}}) => setModifiedCpc(value)}
            label="Edit Site Bid"
            value={modifiedCpc}
            numeric
            name="site-bid"
            startAdornment={<span>$</span>}
            size={{m: '0 20px 0 0', width: '125px'}}
            error={!!errorModifiedCpc}
            helperText={errorModifiedCpc}
            disabled={showEditButton}
          />
					{(params.row.modifiedCpc !== params.row.cpc) &&
            <Tooltip title="Reset site bid to match the campaign cpc">
              <IconButton
                id={`remove-site-bid-${params.row.siteID}`}
                onClick={() => editModifiedCPC(false)}
                disabled={submitting}
                size="small"
              >
								{submitting ? <CircularProgress size={25} color="inherit"/> :
									<Minus className="edit-image-button-icon icon-red"/>
								}
              </IconButton>
            </Tooltip>
					}
          <div className="edit-bid-buttons">
						{showEditButton ?
							<Tooltip title="Edit site bid">
								<IconButton
									id={`edit-bid-button-${params.row.siteID}`}
									size="small"
									onClick={() => setShowEditButton(false)}
								>
									<Edit className="edit-image-button-icon"/>

								</IconButton>
							</Tooltip>
							:
							<>
								<Tooltip title="Cancel editing bid">
									<IconButton
										id={`cancel-site-bid-${params.row.siteID}`}
										size="small"
										onClick={() => {
											setModifiedCpc(params.row.modifiedCpc);
											setShowEditButton(true);
										}}
										disabled={submitting}
										className="edit-bid-button-cancel"
									>

										<Close className="edit-image-button-icon icon-white"/>
									</IconButton>
								</Tooltip>
								<Tooltip title="Save new bid">
									<IconButton
										className={`edit-bid-button-save ${submitting || (Number(modifiedCpc) === Number(params.row.modifiedCpc)) ? 'disabled-bid-button-save' : ''}`}
										size="small"
										id={`edit-site-bid-${params.row.siteID}`}
										onClick={() => editModifiedCPC(true)}
										disabled={submitting || (Number(modifiedCpc) === Number(params.row.modifiedCpc))}
									>
										{submitting ? <CircularProgress size={25} color="inherit"/> :
											<Check className="edit-image-button-icon icon-white"/>
										}
									</IconButton>
								</Tooltip>
							</>
						}
          </div>
        </div>}
		</div>
	);
}