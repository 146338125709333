import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import ReportTable from "../../../../DataTable/ReportTable.tsx";

const columns = [
	{
		field: 'clickDate', headerName: 'Timestamp', width: 190, type: 'timestamp'
	},
	{
		field: 'siteName', headerName: 'Site Name', width: 220, renderCell: (params: never) => (<a target="_blank" className="manage-name-link" href={`https://${params.value}`}>{params.value}</a>),
	},
	...commonColumns(
		[
			'cpc',
		],
	),
	{
		field: 'ip', headerName: 'IP', width: 160,
	},
	{
		field: 'userAgent', headerName: 'User Agent', width: 500,
	},
];

export default function Clicks() {
	return (
		<div className="campaign-details-report">
			<ReportTable
				columns={columns}
				tableName="Clicks"
				endpoint="api/v1/campaign/reporting/clicks"
			/>
		</div>
	);
}
