import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import AreaChart from '../../../../Charts/AreaChart';
import ChartContainer from './ChartContainer.tsx';
import formatCSVData from '../../../../../utils/csvHelper.ts';

const csvColumns = [
	{ id: 'mins', displayName: 'Time' },
	{ id: 'clicks_count', displayName: 'Clicks' },
	{ id: 'conversion', displayName: 'Conversion' },
];

function CustomTooltip({ active, payload, label }: { active: boolean, payload: object[number], label: string }) {
	if (active && payload && payload[0] && payload[0].payload) {
		const values = payload[0].payload;
		return (
			<div className="rounded bg-black/70 px-2 py-1 text-sm font-medium text-white">
				<p className="text-base font-bold text-[#4285F4]">{label}</p>
				<p>Clicks: {values.clicks_count}</p>
				<p>Conversions: {values.conversion}</p>
			</div>
		);
	}
	return null;
}

const formatTextChart = (response) => {
	const emptyTime =  { clicks: 0, conversions: 0 };
	const minutes = [5, 10, 15, 30, 60, 90, 120];
	const tableData = {};
	minutes.forEach(minute => tableData[minute] = response[minute] || { ...emptyTime })
	return tableData;
};
const formatResponseToCharts = (response) => {
	return {
		chartData: {
			chartData: response.chart.map(item => ({ ...item, clicks_count: Number(item.clicks_count), conversion: Number(item.conversion) })) || [],
			textData: formatTextChart(response.text || {}),
		},
		csvData: formatCSVData(response.chart || [], csvColumns),
	};
};

export default function HourlyClicks() {
	return (
		<ChartContainer
			endpoint="api/v1/campaign/reporting/chart/hourlyClicks"
			formatResponse={formatResponseToCharts}
			name="Hourly Clicks"
			ChartComponent={HourlyClicksChart}
			csvColumns={csvColumns}
		/>
	)
}

function HourlyClicksChart({ data: { chartData, textData } }) {
	const minutesTable = (
		<Table aria-label="minutes ago table">
			<TableHead>
				<TableRow>
					<TableCell key="type">Type</TableCell>
					<TableCell align="right" key="type-5">5 Minutes</TableCell>
					<TableCell align="right" key="type-10">10 Minutes</TableCell>
					<TableCell align="right" key="type-15">15 Minutes</TableCell>
					<TableCell align="right" key="type-30">30 Minutes</TableCell>
					<TableCell align="right" key="type-60">60 Minutes</TableCell>
					<TableCell align="right" key="type-90">90 Minutes</TableCell>
					<TableCell align="right" key="type-120">120 Minutes</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow key="clicks">
					<TableCell component="th" scope="row" key="clicks-cell">
						Clicks
					</TableCell>
					{Object.keys(textData).map(minute => (
						<TableCell align="right" key={`clicks-cell-${minute}`}>{textData[minute].clicks}</TableCell>
					))}
				</TableRow>
				<TableRow key="conversions">
					<TableCell component="th" scope="row" key="conversions-cell">
						Conversions
					</TableCell>
					{Object.keys(textData).map(minute => (
						<TableCell align="right" key={`conversions-cell-${minute}`}>{textData[minute].conversions}</TableCell>
					))}
				</TableRow>
			</TableBody>
		</Table>
	)

	return (
		<>
			<AreaChart
				height={280}
				fields={[
					{ name: 'conversion', color: '#8a85ff' },
					{ name: 'clicks_count', color: '#57A16C' },
				]}
				data={chartData}
				dataKey="mins"
				showDollar={false}
				CustomTooltip={CustomTooltip}
				legend
			/>
			<div className="campaign-details-charts-text">
				{minutesTable}
			</div>
		</>
	);
}
