import {budgetValidation, cpcValidation} from "../../Components/Validation/TaboolaDetails.tsx";

export const daysMapping: object = {
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thursday',
	5: 'friday',
	6: 'saturday',
	7: 'sunday',
	8: 'week days',
	9: 'week ends',
};

export const daysToIDMapping: object = {
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
	sunday: 7,
};

const timeHours = [
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

const timeMinutes = [0, 15, 30, 45];
const timeMinutesTaboola = [0];

const formatTimeOptions = (maximusDayParting: boolean, restrictHour = { minHour: 0, minMinute: 0, origTime: '' }) => {
	const returnTimes = [];
	timeHours.forEach((hour) => {
		let time = '';
		if (!restrictHour || hour >= restrictHour.minHour) {
			const amOrPm = hour < 12 ? 'am' : 'pm';
			const formattedHour = (hour > 12 ? hour - 12 : hour) || 0;
      [...(maximusDayParting ? timeMinutes : timeMinutesTaboola)].forEach((minute) => {
				if (!restrictHour || hour !== restrictHour.minHour || minute >= restrictHour.minMinute) {
					time = `${formattedHour || 12}:${minute.toString().length > 1 ? minute : `${minute}0`}${amOrPm}`;
					if (time) {
						if (time !== restrictHour.origTime) {
							returnTimes.push(time);
						}
						time = '';
					}
				}
			});
			if (hour === 23) {
				returnTimes.push('11:59pm');
			}
		}
	});
	return returnTimes;
};

export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
export const weekEnds = ['saturday', 'sunday'];

export const isDayValid = (day) => !!(daysMapping[day]);

export const encodeTimeString = (startAt, endAt) => btoa(`${startAt}${endAt}`);

export const convertTimeTo24HR = (time) => {
	if (time.includes('12')) {
		if (time.includes('am')) {
			return 0;
		}
		return 12;
	}
	return (
		time.toLowerCase().includes('am')
			? +(time.toLowerCase().replace('am', '').split(':')[0])
			: +(time.toLowerCase().replace('pm', '').split(':')[0]) + 12
	);
};

export const createTimeString = (time) => {
	const hour = convertTimeTo24HR(time);
	const minute = time.replace('am', '').replace('pm', '').split(':')[1];
	return `${hour}:${minute}`;
};

export const formatDayPartingForSchedule = (data: Array<object>, maximusDayParting: boolean): Array<object> => {
	const returnData = [];
	const daysBucket = {};
	Object.values(daysMapping).forEach((day) => {
		data.forEach((dayParting) => {
			if (dayParting.days.includes(day)) {
				daysBucket[day] = [
					...(daysBucket[day] || []),
          maximusDayParting ? {
						id: dayParting.id,
						day,
						startAt: dayParting.startAt,
						pauseAt: dayParting.pauseAt,
						cpc: dayParting.cpc,
						budget: dayParting.budget,
						enableCPC: dayParting.enableCPC,
						enableBudget: dayParting.enableBudget,
						unixPauseAt: dayParting.unixPauseAt,
          } : {
            id: dayParting.id,
            day,
            startAt: dayParting.startAt,
            pauseAt: dayParting.pauseAt,
            status: dayParting.status,
            unixPauseAt: dayParting.unixPauseAt,
					},
				];
			}
		});
	});

	Object.keys(daysBucket).forEach((day) => {
		daysBucket[day] = daysBucket[day].sort((a, b) => a.unixPauseAt - b.unixPauseAt);
		daysBucket[day][0].firstTime = true;
	});

	Object.values(daysBucket).forEach((day) => {
		returnData.push(...day);
	});

	return returnData;
};

export const renderLaterTimes = (startTime, maximusDayParting: boolean, shouldFilter = false) => {
	let times;
	if (shouldFilter) {
		const restriction = {
			minHour: convertTimeTo24HR(startTime === '0' ? '12:00am' : startTime),
			minMinute: startTime.replace('am', '').replace('pm', '').split(':')[1],
			origTime: startTime,
		};
    times = formatTimeOptions(maximusDayParting, restriction);
	} else {
    times = formatTimeOptions(maximusDayParting);
	}
	return [{label: 'Select a time', value: '0'}, ...times.map((time) => ({label: time, value: time}))];
};

export const removeDayFromDayParting = (dayParting: Array<object>, id: string, day: string): Array<object> => {
	const decodedDayParting = JSON.parse(JSON.stringify(dayParting));
	const returnedDayParting = [];
	decodedDayParting.forEach((dayPart) => {
		if (dayPart.id === id) {
			if (dayPart.days.includes(day)) {
				if (dayPart.days.length !== 1) {
					const arrayWithoutDeletedDay = dayPart.days.filter((dayItem) => dayItem !== day);
					returnedDayParting.push({...dayPart, days: arrayWithoutDeletedDay});
				}
			}
		} else {
			returnedDayParting.push(dayPart);
		}
	});
	return returnedDayParting;
}

interface DayParting {
	days: Array<string>,
	startAt: string,
	pauseAt: string,
	budget: string,
	cpc: string,
	enableBudget: boolean,
	enableCPC: boolean,
}

const getCombinedHour = (time) => {
	let hour = time.replace('am', '').replace('pm', '').split(':')[0];
	hour === '12' ? hour = '0' : null;
	const minutes = time.replace('am', '').replace('pm', '').split(':')[1];
	return ((+hour + (time.includes('pm') ? 12 : 0)) * 60) + +minutes;
}

export const returnDayPartingErrors = (dayParting: DayParting, showCpc = true, maximusDayParting = true): object => {
	const {
		days,
		startAt,
		pauseAt,
		budget,
		cpc,
		enableBudget,
		enableCPC
	} = dayParting;
	const errors = {
		days: '',
		startAt: '',
		pauseAt: '',
		budget: '',
		cpc: '',
	};
	let valid = true

	if (!days.length) {
		valid = false;
		errors.days = 'Please select at least one day.';
	}

	if (!startAt || +startAt === 0) {
		valid = false;
		errors.startAt = 'Please select a start time.';
	}

	if (!pauseAt || +pauseAt === 0) {
		valid = false;
		errors.pauseAt = 'Please select a pause time.';
	}

	if (startAt && +startAt !== 0 && pauseAt && +pauseAt !== 0) {
		const combinedStartTime = getCombinedHour(startAt);
		const combinedPauseTime = getCombinedHour(pauseAt);

		if (+combinedStartTime <= +combinedPauseTime) {
			valid = false;
			errors.startAt = 'Please select a start time that is after the pause time.';
		}
	}

  if (maximusDayParting) {
    if (enableBudget && !budget) {
      valid = false;
      errors.budget = 'Please enter a budget.';
    } else if (enableBudget) {
      if (budgetValidation(budget)) {
        valid = false;
        errors.budget = budgetValidation(budget);
      }
    }

    if (showCpc && enableCPC && !cpc) {
      valid = false;
      errors.cpc = 'Please enter a CPC.';
    } else if (enableCPC && showCpc) {
      if (cpcValidation(cpc)) {
        valid = false;
        errors.cpc = cpcValidation(cpc);
      }
		}
	}

	return {valid, errors};
};