import { useState } from 'react';
import lodash from 'lodash';
import { format, setHours } from 'date-fns';
import { Tab, Tabs } from '@mui/material';
import AreaChart from '../../../../Charts/AreaChart';
import { conditionalProfitClass } from '../../../../../utils/formatNumbers.ts';
import ChartContainer from './ChartContainer.tsx';

const { capitalize, range, startCase } = lodash;

const weekAgoLabel = `last_${format(new Date(), 'EEEE')}`;
const csvColumns = [
	{ id: 'hour', displayName: 'Hour' },
	{ id: 'today_clicks', displayName: 'Today Clicks' },
	{ id: 'yesterday_clicks', displayName: 'Yesterday Clicks' },
	{ id: 'week_ago_clicks', displayName: `${startCase(weekAgoLabel)} Clicks` },
	{ id: 'today_revenue', displayName: 'Today Revenue' },
	{ id: 'yesterday_revenue', displayName: 'Yesterday Revenue' },
	{ id: 'week_ago_revenue', displayName: `${startCase(weekAgoLabel)} Revenue` },
	{ id: 'today_profit', displayName: 'Today Profit' },
	{ id: 'yesterday_profit', displayName: 'Yesterday Profit' },
	{ id: 'week_ago_profit', displayName: `${startCase(weekAgoLabel)} Profit` },
];

const generateCustomTooltip = (chartType: string) => {
	const showDollar = chartType !== 'clicks' ? '$' : '';
	const valueFormatter = (value: number) => {
		if (chartType === 'profit') {
			return <span className={conditionalProfitClass('profit', value)}>${value}</span>;
		}
		return `${showDollar}${value}`;
	}
	return function CustomTooltip({ active, payload, label }: { active: boolean, payload: object[number], label: string }) {
		if (active && payload && payload[0] && payload[0].payload) {
			const values = payload[0].payload;
			return (
				<div className="rounded bg-black/70 px-2 py-1 text-sm font-medium text-white">
					<p className="text-base font-bold text-[#4285F4]">{format(setHours(new Date(), label), 'haa')}</p>
					<p>Last {format(new Date(), 'EEEE')}: {valueFormatter(values[weekAgoLabel])}</p>
					<p>Yesterday: {valueFormatter(values.yesterday)}</p>
					<p>Today: {valueFormatter(values.today)}</p>
				</div>
			);
		}
		return null;
	}
}

const formatResponseToCSV = (response) => {
	return range(24).map((hour: number) => {
		return ({
			hour,
			today_clicks: response.clicks.today ? response.clicks.today[hour] || 0 : 0,
			yesterday_clicks: response.clicks.yesterday ? response.clicks.yesterday[hour] || 0 : 0,
			week_ago_clicks: response.clicks.week_ago ? response.clicks.week_ago[hour] || 0 : 0,
			today_profit: response.profit.today ? response.profit.today[hour] || 0 : 0,
			yesterday_profit: response.profit.yesterday ? response.profit.yesterday[hour] || 0 : 0,
			week_ago_profit: response.profit.week_ago ? response.profit.week_ago[hour] || 0 : 0,
			today_revenue: response.revenue.today ? response.revenue.today[hour] || 0 : 0,
			yesterday_revenue: response.revenue.yesterday ? response.revenue.yesterday[hour] || 0 : 0,
			week_ago_revenue: response.revenue.week_ago ? response.revenue.week_ago[hour] || 0 : 0,
		});
	});
};

const formatResponseToCharts = (response) => {
	const newChartData : { [index: string]: [] } = {};
	Object.keys(response).forEach((typeData: string) => {
		newChartData[typeData] = range(24).map((hour: number) => {
			return {
				hour: hour,
				[weekAgoLabel]: response[typeData].week_ago ? Number(response[typeData].week_ago[hour]) || 0 : 0,
				yesterday: response[typeData].yesterday ? Number(response[typeData].yesterday[hour]) || 0 : 0,
				today: response[typeData].today ? Number(response[typeData].today[hour]) || 0 : 0,
			};
		});
	});
	const csvData = formatResponseToCSV(response);
	return { chartData: newChartData, csvData };
};

export default function HourlyComparison() {
	return (
		<ChartContainer
			endpoint="api/v1/campaign/reporting/chart/hourlyComparison"
			formatResponse={formatResponseToCharts}
			name="Hourly Comparison"
			ChartComponent={HourlyComparisonChart}
			csvColumns={csvColumns}
		/>
	)
}

function HourlyComparisonChart({ data: chartData }) {
	const [selectedChart, setSelectedChart] = useState<number>(0);
	const tabs = ['clicks', 'profit', 'revenue'];

	return (
		<>
			<div className="campaign-details-charts-tabs">
				<Tabs
					value={selectedChart}
					onChange={(e, tabKey: number) => setSelectedChart(tabKey)}
				>
					{tabs.map((label, i) => (
						<Tab
							key={i}
							disableRipple
							label={capitalize(label)}
						/>
					))}
				</Tabs>
			</div>
			<AreaChart
				height={270}
				fields={[
					{ name: 'today', color: '#8a85ff' },
					{ name: 'yesterday', color: '#57A16C' },
					{ name: weekAgoLabel, color: '#de5b5d' },
				]}
				data={chartData[tabs[selectedChart]]}
				dataKey="hour"
				showDollar={tabs[selectedChart] !== 'clicks'}
				CustomTooltip={generateCustomTooltip(tabs[selectedChart])}
				legend
			/>
		</>);
}
