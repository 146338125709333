import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { ValidateStepProps } from '../../../AddCampaign/Components/StepsValidation.tsx';
import { changeNonField } from '../../../../../Redux/Slices/addCampaign.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';
import {validateFireAndForget} from "../../../AddCampaign/Components/Validation/FireAndForget.tsx";

export const useSubmitFireAndForget = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network, hasCpc } = useAppSelector((state) => state.campaignDetails);
	const { fireAndForget } = useAppSelector((state) => state.addCampaign);

	return async () =>  {
		const newErrors: ValidateStepProps = validateFireAndForget(fireAndForget, hasCpc);
		dispatch(changeNonField({ key: 'errors', value: newErrors }));
		if (Object.keys(newErrors.fields).length) {
			return;
		}
		await networkRequest('api/v1/campaign/fnf/update', {
			campaignID,
			network,
			...fireAndForget,
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object' && response.success) {
					dispatch({ type: 'campaignDetails/closeDialog' });
				} else {
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error'
						}
					));
				}
			}
		);
	}
}
