import CopyAdsButton from './CopyAdsButton.tsx';
import SectionTitle from '../../Components/SectionTitle.tsx';
import AddAdsSection from './AddAdsSection.tsx';
import DisplayAdsSection from './DisplayAdsSection.tsx';

export default function AdSetup() {
  return (
    <div className="ad-setup-container">
      <SectionTitle
        title="Ad Setup"
        EndAddition={<CopyAdsButton />}
      />
      <div className="ad-setup-add-display-container">
        <AddAdsSection />
        <DisplayAdsSection />
      </div>
    </div>
  );
}
