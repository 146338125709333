import {FormControl, NativeSelect} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {changeFilter} from "../../Redux/Slices/filters";
import {useEffect, useMemo, useState} from "react";
import networkRequest from "../../utils/networkRequest";

export default function UserFilter() {
	const user = useAppSelector((state) => state.filters.user);
	const {userID} = useAppSelector((state) => state.main);
	const [users, setUsers] = useState([{user_id: '-1', name: 'All Users'}]);
	const dispatch = useAppDispatch();

	useEffect(() => {
		pullUsers();
	}, []);

	function pullUsers() {
		networkRequest('api/v1/user/list/pull', {userID}, 'POST')
			.then((response) => response.json())
			.then((response) => {
					if (response && typeof response === 'object') {
						setUsers([...users, ...response]);
					}
				}
			);
	}

	const handleChange = ({target: {value}}) => {
		dispatch(changeFilter({filter: 'user', value}));
	};

	const userOptions = useMemo(() => {
		return users.map((user) => {
			return <option key={user.user_id} value={user.user_id}>{user.name}</option>
		});
	}, [users]);

	return (
		<FormControl className="select-filter-form-control" variant="standard" sx={{m: 1, minWidth: 120}}>
			<NativeSelect
				id="user-filter"
				value={user}
				onChange={handleChange}
				label="User"
			>
				{userOptions}
			</NativeSelect>
		</FormControl>
	)
}
