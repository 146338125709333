import {commonColumns} from '../../../../../utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import {formatPercent} from "../../../../../utils/formatNumbers.ts";
import {CurrencyComparator} from "../../../../../utils/tables-utils.tsx";
import {useAppSelector} from "../../../../../hooks.ts";

export const getOttoPresellName = (url: string): string => {
	try {
		const partsOfPresellUrl = url.split('//')[1].split('/');
		if (partsOfPresellUrl[2]) {
			return partsOfPresellUrl[2].split('?')[0];
		} else {
			return partsOfPresellUrl[1].split('?')[1];
		}
	} catch (e) {
		return url;
	}
}

const columns = (isOtto: boolean) => ([
	{
		field: 'url',
		headerName: 'Presell URL',
		width: 200,
		renderCell: (params: { value: string }) => (
			<a target="_blank" className="manage-name-link" href={params.value}>
				{isOtto ? getOttoPresellName(params.value) : params.value}
			</a>
		),
	},
	{
		field: 'ctr',
		headerName: 'Presell CTR',
		width: 120,
		renderCell: (params: { value: string }) => formatPercent(params.value),
		sortComparator: CurrencyComparator,
		type: 'number',
	},
	...commonColumns(
		[
			'visits',
			'clicks',
			'conversions',
			'epc',
			'spend',
			'revenue',
			'profit',
			'profit_percent',
			'cvr',
		],
	),
]);

export default function Presells() {
	const { estimatedSpends, isOtto } = useAppSelector((state) => state.campaignDetails);
	return (
		<div className="campaign-details-report">
			{!estimatedSpends ? (
				<div className="states-report-disclaimer w-full text-center font-bold">
					* This report is built <span className="underline">using estimates</span> so it may not match campaigns that use network data *
				</div>
			) : ''}
			<ReportTable
				columns={columns(isOtto)}
				tableName="Presells"
				endpoint="api/v1/campaign/reporting/presell"
				aggregationModel={{
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					cpc: 'avg',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					cvr: 'avg',
					epc: 'avg',
					spend: 'sum',
					revenue: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					presell_ctr: 'avg',
					presell_url: 'blankColumn',
				}}
			/>
		</div>
	);
}
