import Sites from './Sites/Sites';
import Images from './Images';
import ByDate from './ByDate';
import Clicks from './Clicks';
import Conversions from './Conversions';
import ImageSitePresell from './ImageSitePresell';
import Platforms from './Platforms';
import Landing from './Landing';
import Presells from './Presells';
import States from './States/States.tsx';
import CampaignLog from './CampaignLog';
import AllImages from './AllImages';

export default {
	Sites,
	Images,
	ByDate,
	Clicks,
	Conversions,
	ImageSitePresell,
	Platforms,
	Landing,
	Presells,
	States,
	CampaignLog,
	AllImages,
};
