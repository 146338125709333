import {DataGridPremium, GRID_AGGREGATION_FUNCTIONS, GridColDef} from '@mui/x-data-grid-premium';
import {CustomToolBar} from './CustomFilterBar';
import {CustomNoRowsOverlay} from './NoRowsDisplay';
import { useScrollbarSize } from '../../utils/useScrollbarSize.tsx';
import MaximusLoader from '../Loaders/MaximusLoader.tsx';
import CUSTOM_AGGREGATION_FUNCTIONS from "./AggregationFunctions";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import { updateMainStore } from '../../Redux/Slices/main';

function DataGrid(
	{
		rows = [] as any[],
		columns = [] as GridColDef[],
		pageSize = 5,
		rowsPerPage,
		height = 400,
		width = '100%',
		filterToolbar = true,
		checkboxSelection = false,
		hideQuick = false,
		hideExport = false,
		columnVisibilityModel = {id: false},
		sortModel = [],
		aggregationModel,
		tableName = '',
		dateRangeTitle = '',
		...miscOptions
	},
) {
	const [components, setComponents] = useState(
		{
			noRowsOverlay: CustomNoRowsOverlay,
			loadingOverlay: MaximusLoader,
		}
	);

	const { dataGridCustomization } = useAppSelector((state) => state.main);
	const {forceRefreshCount} = dataGridCustomization;
	const dispatch = useAppDispatch();
	const scrollbarSize = useScrollbarSize(document.body, 0.001); // Visually close to 0px, as real 0 causes scrolling issues with the DataGrid

	useEffect(() => {
		if (filterToolbar) {
			setComponents({
				...components,
				toolbar: () => CustomToolBar(
					hideQuick,
					hideExport,
					tableName,
					dateRangeTitle,
					() => dispatch(updateMainStore({key: 'dataGridCustomization', value: {...dataGridCustomization, forceRefreshCount: forceRefreshCount + 1}}))
				),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableName, columns, forceRefreshCount])

	return (
		<div style={{height, width}}>
			<DataGridPremium
				className="text-base"
				rows={rows}
				columns={columns}
				aggregationFunctions={{
					...GRID_AGGREGATION_FUNCTIONS,
					...CUSTOM_AGGREGATION_FUNCTIONS,
				}}
				initialState={{
					columns: {
						columnVisibilityModel,
					},
					sorting: {
						sortModel,
					},
					aggregation: {
						model: aggregationModel,
					},
				}}
				pageSize={pageSize || 5}
				rowsPerPageOptions={[rowsPerPage || 5]}
				slots={components}
				checkboxSelection={checkboxSelection}
				getAggregationPosition={() => 'footer'}
				scrollbarSize={scrollbarSize}
				{...miscOptions}
			/>
		</div>
	);
}


export default DataGrid;
