import {useAppDispatch, useAppSelector} from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import {ValidateStepProps} from '../../../AddCampaign/Components/StepsValidation.tsx';
import {changeNonField, changeSettingsField} from '../../../../../Redux/Slices/addCampaign.ts';
import {openSnackbar} from '../../../../../Redux/Slices/main.ts';
import {changeCampaignDetails} from '../../../../../Redux/Slices/campaignDetails.ts';
import {
  validateTaboolaSettings,
  budgetCPCTaboolaValidation
} from "../../../AddCampaign/Components/Validation/TaboolaDetails.tsx";
import {disableFFStatus} from '../../Glance/helper.ts';

export const useSubmitSettings = () => {
  const dispatch = useAppDispatch();
  const {campaignID, network} = useAppSelector((state) => state.campaignDetails);
  const {settings} = useAppSelector((state) => state.addCampaign);

  return async (confirmedBudgetChange = false) => {
    const newBudget = Number(settings.cpc) * 30;
    if (confirmedBudgetChange) {
      dispatch(changeSettingsField({name: 'budget', value: newBudget}));
    }
    const newErrors: ValidateStepProps = validateTaboolaSettings(settings, true);
    dispatch(changeNonField({key: 'errors', value: newErrors}));
    if (Object.keys(newErrors.fields).length) {
      return;
    }
    return await networkRequest('api/v1/campaign/settings/update', {
      campaignID,
      network,
      budget: Number(confirmedBudgetChange ? newBudget : settings.budget),
      cpc: (settings.bidStrategy === '1' || settings.bidStrategy === '2') ? settings.cpc : undefined,
      conversionCapTimeframe: settings.conversionCapTimeframe,
      conversionCapThreshold: settings.conversionCapThreshold,
      isAgencyCampaign: settings.isAgencyCampaign,
      usmID: settings.usmID,
      osTargeting: settings.osTargeting,
      platformTargeting: settings.platformTargeting.length === 1 && settings.platformTargeting[0] === '0' ? ['3', '2', '1'] : settings.platformTargeting,
      bidStrategy: settings.bidStrategy,
      connectionType: settings.connectionType,
      browserTargeting: settings.browserTargeting,
      marketingObjective: settings.marketingObjective,
      brandingText: settings.brandingText,
      vertical: settings.vertical,
      affiliate: settings.affiliate,
      s1: settings.s1,
      isOtto: settings.isOttoCampaign,
      trafficAllocationMode: settings.creativeTrafficAllocation,
      targetCPA: settings.bidStrategy === '4' ? settings.targetCPA : undefined,
      name: settings.campaignName,
    }, 'POST')
      .then((response) => response.json())
      .then((response) => {
          if (response && typeof response === 'object' && response.success) {
            if (response.warnings?.length || response.confirms?.length) {
              return {warnings: response.warnings || [], confirms: response.confirms || []};
            } else {
              dispatch({type: 'campaignDetails/closeDialog'});
              dispatch(changeCampaignDetails({key: 'updatedCampaignSettings', value: true}));
            }
          } else {
            dispatch(openSnackbar(
              {
                children: response.message || 'Something went wrong. Please try again.',
                severity: 'error'
              }
            ));
          }
        }
      );
  }
}

export const useDisableFFStatusConfirm = () => {
  const dispatch = useAppDispatch();
  const {campaignID, network} = useAppSelector((state) => state.campaignDetails);
  return async () => disableFFStatus(campaignID, network, dispatch);
}

export const checkConfirmBudget = ({budget, cpc, bidStrategy}: {
  budget?: string,
  cpc?: string,
  bidStrategy?: string
}) => {
  if (budgetCPCTaboolaValidation(budget, cpc, bidStrategy)) {
    const newBudget = Number(cpc) * 30;
    return `Taboola requires a minimum budget of 30x the CPC and so we’d need to update the budget to $${newBudget} in order to process this change. Do you want to continue with this change by letting Maximus update your budget to $${newBudget} as well?`;
  }
  return '';
}
