import { AppDispatch } from '../../../../Redux/reduxStore.ts';
import networkRequest from '../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../Redux/Slices/main.ts';

export const disableFFStatus = (campaignID: string, network: number, dispatch: AppDispatch) =>
  networkRequest('api/v1/campaign/fnf/status/update', { campaignID, network, fireAndForgetStatus: false }, 'POST')
  .then((response) => response.json())
  .then((response) => {
      if (response && typeof response === 'object') {
        if (!response.success) {
          dispatch(openSnackbar(
            {
              children: response.message || 'Something went wrong. Please try again',
              severity: 'error'
            }
          ));
        } else {
          dispatch(openSnackbar({ children: 'F&F Status was updated successfully.', severity: 'success' }));
        }
      }
    }
  );
