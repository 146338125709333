import {ValidateStepProps} from "../StepsValidation.tsx";

export const cpcValidation = (cpc: string) => {
	let message = '';
	if (Number(cpc) < 0.01 || Number(cpc) > 5) {
		message = 'CPC needs to be between $0.01 and $5.';
	}
	return message
}

export const budgetCPCTaboolaValidation = (budget?: string, cpc?: string, bidStrategy?: string) =>
	(bidStrategy === '1' || bidStrategy === '2') && (Number(budget) < 30 * Number(cpc));
export const targetCpaValidation = (targetCPA: string) => {
	const min = 0.01;
	const max = 100;
	if (Number(targetCPA) < min || Number(targetCPA) > max) {
		return `Target CPA needs to be between $${min.toFixed(2)} and $${max.toFixed(2)}.`;
	}
	return ''
}
export const budgetValidation = (budget?: string) => {
	let message = '';
	if (Number(budget) < 10) {
		message = 'Budget needs to be greater than $10.';
	}
	return message;
}
export const validateTaboolaSettings = (settings: object, campaignDetails = false): ValidateStepProps => {
	const errors = {fields: {}};
	if (!campaignDetails) {
		if (!settings.campaignName) {
			errors.fields.campaignName = 'Campaign Name should not be empty.';
		}
		if (settings.campaignName.length > 200) {
			errors.fields.campaignName = 'Campaign Name should not be more than 200 symbols.';
		}
		if (/[^a-zA-Z0-9_ ]/.test(settings.campaignName)) {
			errors.fields.campaignName = 'Special characters are not allowed in the name.';
		}
		if (settings.group.toString() === '0') {
			errors.fields.group = 'Please select a group.';
		}
		if (settings.taboolaAccount.toString() === '0') {
			errors.fields.taboolaAccount = 'Please select an account.';
		}
		if (settings.taboolaSubAccount.toString() === '0') {
			errors.fields.taboolaSubAccount = 'Please select a sub account.';
		}
	}
	if (settings.brandingText.length > 25) {
		errors.fields.brandingText = 'Branding Text should not be more than 25 symbols.';
	}
	if (!settings.brandingText) {
		errors.fields.brandingText = 'Please enter a value for branding text.';
	}
	if (settings.isOttoCampaign) {
		if (!settings.affiliate) {
			errors.fields.affiliate = 'Please enter a value for OTTO Affiliate ID.';
		}
		if (!settings.s1) {
			errors.fields.s1 = 'Please enter a value for OTTO S1.';
		}
	}
	if (settings.platformTargeting.length === 0) {
		errors.fields.platformTargeting = 'Please select a value for platform targeting.';
	}
	if (!(['3', '4'].includes(settings.bidStrategy)) && cpcValidation(settings.cpc)) {
		errors.fields.cpc = cpcValidation(settings.cpc);
	}

	if (settings.bidStrategy === '4') {
		if (targetCpaValidation(settings.targetCPA)) {
			errors.fields.targetCPA = targetCpaValidation(settings.targetCPA)
		}
		if (settings.creativeTrafficAllocation === '1') {
			errors.fields.creativeTrafficAllocation = 'Traffic Allocation mode could not be Even when Bid Strategy is Target CPA.';
		}
		if (settings.marketingObjective === '1') {
			errors.fields.marketingObjective = 'Marketing Objective cannot not be set to Brand Awareness when Bid Strategy is Target CPA.';
		}
	}

	if (budgetValidation(settings.budget)) {
		errors.fields.budget = budgetValidation(settings.budget);
	}
	return errors;
};