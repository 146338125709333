import { Box, Chip, FilledInput, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface SelectProps {
	options: Array<{ value: string, label: string }>,
	onChange: (event: SelectChangeEvent<string[]>) => void,
	label?: string,
	value: string[],
	name: string,
	error?: boolean,
	helperText?: string,
	disabled?: boolean,
	size?: { m: string, width: string },
}

export default function ChipSelect(
	{
		options,
		onChange,
		label = '',
		value,
		name,
		error = false,
		helperText = '',
		disabled = false,
		size = {m: '12px', width: 'calc(33.33% - 24px)'},
	}: SelectProps
) {

	const chipLabelFromOptions = (selected) => {
		let label = '';
		options.forEach((option) => {
			if (option.value === selected) {
				label = option.label;
			}
		})
		return label;
	}

	return (
		<FormControl error={error} sx={size} variant="filled">
			<InputLabel id="demo-multiple-chip-label">
				{label}
			</InputLabel>
			<Select
				onChange={onChange}
				value={value}
				name={name}
				multiple
				disabled={disabled}
				input={
					<FilledInput
						id={`select-${name}`}
						label={label}
						name={name}
						variant="filled"
					/>
				}
				renderValue={(selected) => (
					<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
						{selected.map((value) => (
							<Chip key={value} label={chipLabelFromOptions(value)}/>
						))}
					</Box>
				)}
			>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}
