import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function HighestBid(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			highestBid,
			fireAndForgetStatus,
		},
		errors: {
			fields: {
				highestBid: highestBidError
			}
		},
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('highestBid', value)}
			label="Highest Bid"
			value={highestBid}
			name="highest-bid"
			numeric
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={fireAndForgetStatus === false}
			error={highestBidError}
			helperText={highestBidError}
		/>
	)
}
