import { useState } from 'react';
import {CircularProgress} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Check from '../../../../Icons/Check.tsx';
import networkRequest from '../../../../utils/networkRequest.ts';
import { useAppDispatch, useAppSelector } from '../../../../hooks.ts';
import { daysToIDMapping } from '../Pages/DayParting/dayPartingHelper.ts';
import validateStep, {ValidateStepProps} from './StepsValidation.tsx';
import {changeNonField, resetAddCampaign} from '../../../../Redux/Slices/addCampaign.ts';
import {openSnackbar} from "../../../../Redux/Slices/main";
import {useErrorHelper} from "../../../../utils/useErrorHelper";

export const modifyDayPartingForParams = (dayParting: object[]) => (
	dayParting.map((item) => ({
		...item,
		days: item.days.map((day: string) => daysToIDMapping[day]),
	})));

export const modifyAdsForParams = (ads: object[]) => (
	ads.map((ad) => ({
		imageUrl: ad.image,
		fileType: ad.fileType,
		title: ad.headline,
		description: ad.description,
		cta: ad.callToAction,
    thumbnail: ad.thumbnail || undefined,
	})));

export default function SubmitCampaign() {
  const dispatch = useAppDispatch();
	const errorHelper = useErrorHelper();
  const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
  const addCampaignState = useAppSelector((state) => state.addCampaign);

  const combineParams = () => {
    const {
      settings, fireAndForget, ads, dayParting,
      link, presellLinks, landingPageLinks,
      advancedTracking, estimatedSpends, maximusDayParting, networkDayParting,
    } = addCampaignState;
    let params = {
			...settings,
      name: settings.campaignName,
      network: 1,
			groupID: settings.group,
			accountId: settings.taboolaAccount,
			subAccountId: settings.taboolaSubAccount,
      trafficAllocationMode: settings.creativeTrafficAllocation,
      advancedTracking: advancedTracking,
      estimatedSpends,
      timeframe: settings.conversionCapTimeframe,
      conversionCap: settings.conversionCapTimeframe !== '0' ? settings.conversionCapThreshold : '0',
      isOtto: settings.isOttoCampaign,
      splitByDevice: settings.platformTargeting.includes('0') ? settings.splitByDevice : false,
    };
    params = {
      ...params,
      ...fireAndForget,
      ads: modifyAdsForParams(ads),
      maximusDayParting,
      dayParting: maximusDayParting ? modifyDayPartingForParams(dayParting) : [],
      networkDayParting: !maximusDayParting ? modifyDayPartingForParams(networkDayParting) : [],
      link,
      presellLinks,
      landingPageLinks,
    };

    return params;
  };

  const createCampaign = async() => {
    setLoading(true);
    networkRequest('api/v1/campaign/create', combineParams(), 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object') {
          if (response.success) {
						dispatch(openSnackbar({children: 'Campaign Successfully Created', severity: 'success'}));
						dispatch(resetAddCampaign());
						navigate('/');
					} else if (response.errors) {
						errorHelper(response.errors);
					} else {
						errorHelper('Something went wrong. Please check your settings and try again.');
					}
        }
        setLoading(false);
      }
    )
    .catch(() => {
      setLoading(false);
    });
  };

  const validateAds = () => {
    const newErrors: ValidateStepProps = validateStep(5, addCampaignState);
    dispatch(changeNonField({ key: 'errors', value: newErrors }));
    return !Object.keys(newErrors.fields).length;
  };

  const submit = () => {
    if (validateAds()) {
      return createCampaign();
    }
		return false;
  };

  return (
    <button type="button" onClick={submit} className="stepper-submit-button" disabled={loading}>
			{loading
				? <CircularProgress size={40} />
				: <Check/>
			}
    </button>
  );
}
