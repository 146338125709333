import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { changeCampaignDetails, resetCampaignDetails } from '../../../Redux/Slices/campaignDetails';
import networkRequest from "../../../utils/networkRequest";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import { openSnackbar } from '../../../Redux/Slices/main.ts';
import {changeSettingsField, resetAddCampaign} from '../../../Redux/Slices/addCampaign.ts';

export const useCampaignNotificationsFunc = () => {
	const dispatch = useAppDispatch();
	return (campaignID: string) =>
		networkRequest('api/v1/campaign/details/notifications', {
			campaignID,
		}, 'POST')
		.then((response) => response.json())
		.then((campaignNotifications) => {
			dispatch(changeCampaignDetails({key: 'campaignNotifications', value: campaignNotifications}));
		});
}

export const useNoCampaignIDError = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	return () => {
		dispatch(
			openSnackbar(
				{
					children: 'Something went wrong while pulling campaign data. Redirecting you to Manage Campaigns.',
					severity: 'error',
				},
			));
		navigate('/');
	}
}

export const useGetCampaignIDFromName = () => {
	const {id: campaignName} = useParams<{ id: string }>();
	const setNoCampaignIDError = useNoCampaignIDError();
	return async () => {
		return networkRequest('api/v1/campaign/details/getIDFromName', {campaignName}, 'POST')
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				}
				setNoCampaignIDError();
				return false;
			})
	}
}

export const useCampaignDetails = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const getCampaignID = useGetCampaignIDFromName();
	const setNoCampaignIDError = useNoCampaignIDError();
	const { updatedCampaignSettings, campaignID } = useAppSelector((state) => state.campaignDetails);
	const {state} = useLocation();
	const {campaignID: campaignIDFromLocation} = state || {};
	const notificationsCall = useCampaignNotificationsFunc();
	const notificationsInterval = useRef();

	useEffect(() => {
		if (campaignIDFromLocation) {
			getCampaignDetails(campaignIDFromLocation);
		} else {
			getCampaignIDFromName()
		}
		return () => {
			clearNotificationsInterval();
			dispatch(resetCampaignDetails());
			dispatch(resetAddCampaign());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (updatedCampaignSettings && campaignID) {
			getCampaignDetails(campaignID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCampaignSettings]);

	useEffect(() => {
		if (campaignID) {
			notificationsInterval.current = setInterval(() => notificationsCall(campaignID), 90000);
		}
		// eslint-disable-next-line
	}, [campaignID]);

	const clearNotificationsInterval = () => clearInterval(notificationsInterval.current);

	const getCampaignIDFromName = () => {
		getCampaignID()
			.then((response: { campaignID: string }) => {
				if (response && response.campaignID) {
					getCampaignDetails(response.campaignID)
				} else {
					setNoCampaignIDError();
				}
			})
		return false;
	}

	const getCampaignDetails = (id: string) => {
		dispatch(changeCampaignDetails({ key: 'campaignID', value: '' }));
		networkRequest('api/v1/campaign/details/pull', {
			campaignID: id,
		}, 'POST')
			.then((response) => {
				if (response.status === 403) {
					dispatch(
						openSnackbar(
							{
								children: 'Your account does not have access to this campaign. Redirecting you to Manage Campaigns.',
								severity: 'error',
							},
						));
					navigate('/');
					return Promise.reject('Access Denied');
				}
				return response.json();
			})
			.then((response) => {
					if (response && typeof response === 'object') {
						const {
							campaignName, network, campaignNotifications,
							maximusLinks, estimatedSpends, hasSiteBids, hasCpc,
							advancedTracking, linkTestNotifications
						} = response;
						dispatch(changeCampaignDetails({key: 'campaignName', value: campaignName || 'Test'}));
						dispatch(changeCampaignDetails({key: 'network', value: network || 1}));
						dispatch(changeCampaignDetails({key: 'campaignID', value: id}));
						dispatch(changeCampaignDetails({key: 'campaignNotifications', value: campaignNotifications || [] }));
						dispatch(changeCampaignDetails({ key: 'linkTestNotification', value: linkTestNotifications[0] || '' }));
						dispatch(changeCampaignDetails({ key: 'maximusLinks', value: maximusLinks || false }));
						dispatch(changeSettingsField({ name: 'maximusLinks', value: maximusLinks || false }));
						dispatch(changeCampaignDetails({ key: 'advancedTracking', value: advancedTracking || false }));
						dispatch(changeCampaignDetails({ key: 'estimatedSpends', value: estimatedSpends || false }));
						dispatch(changeCampaignDetails({ key: 'hasSiteBids', value: hasSiteBids || false }));
						dispatch(changeCampaignDetails({ key: 'hasCpc', value: hasCpc || false }));
						dispatch(changeCampaignDetails({ key: 'updatedCampaignSettings', value: false }));
						dispatch(changeCampaignDetails({ key: 'campaignArchiveStatus', value: response.archiveStatus || false }));
						dispatch(changeCampaignDetails({ key: 'previewLink', value: response.baseLink || '' }));
						dispatch(changeCampaignDetails({ key: 'isOtto', value: response.isOtto || false }));
					}
				}
			)
			.catch((e) => {
				console.error(e);
			});
	};
}
