import { Alert } from '@mui/material';
import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ComponentProps, ReactNode } from 'react';
import { getFromLocalStorage } from '../../utils/local-storage-utils';

export interface MainState {
  userID: string;
  userSettings: {
    theme: string;
    detailsReport: string;
    redirectOnRouting: boolean;
  };
  dialog: {
    children?: ReactNode;
    warningDialog?: boolean;
    cancelText?: string;
    formDialog?: boolean;
    confirmText?: string;
    title?: string;
    cancelAction?: boolean;
    successDialog?: boolean;
    open?: boolean;
    confirmDialog?: boolean
  };
  dataGridCustomization: {
    columnOrder: object;
    hideColumns: object;
    filters: object;
    columnSizes: object;
    forceRefreshCount: number;
  };
  rightDrawer: {
    children: string;
    title: string;
    loading: boolean;
    open: boolean
  };
  snackbar: {
    severity: ComponentProps<typeof Alert>['severity'];
    children: ReactNode;
    open: boolean;
    key: number;
  };
  token: string;
  tableRows: Array<object>;
  email: string;
  loggedIn: boolean;
  appLoaded: boolean;
  helpOverlay: {
    open: boolean
  }
}

interface ActionInterface {
  payload: {
    key?: string;
    value?: never;
    severity?: string;
    children?: ReactNode;
    title?: string;
    loading?: boolean;
    confirmDialog?: boolean;
    formDialog?: boolean;
    successDialog?: boolean;
    warningDialog?: boolean;
    confirmText?: string;
    cancelText?: string;
    cancelAction?: boolean;
  }
}

export const initialState: MainState = {
  userSettings: {
    theme: getFromLocalStorage('userSettings_theme') || 'light',
    detailsReport: getFromLocalStorage('userSettings_detailsReport') || 'Sites',
    redirectOnRouting: (getFromLocalStorage('userSettings_redirectOnRouting') || 'true') === 'true',
  },
  rightDrawer: {
    open: false,
    title: '',
    children: '',
    loading: false,
  },
  snackbar: {
    open: false,
    children: '',
    severity: 'info',
    key: 0,
  },
  dialog: {
    open: false,
    title: '',
    children: '',
    confirmDialog: false,
    formDialog: false,
    successDialog: false,
    warningDialog: false,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    cancelAction: false,
  },
  helpOverlay: {
    open: false,
  },
  email: '',
  loggedIn: false,
  appLoaded: false,
  userID: '',
  token: '',
  tableRows: [
    {
      id: 1,
      adID: 't123a2431a',
      siteID: 'aad33431a',
      presellID: 'saf@23413a',
      routing: false,
      status: true,
      image: '',
      headline: 'test',
      visits: 0,
      clicks: 0,
      presell_ctr: 0,
      sales: 0,
      epc: 0,
      spend: 0,
      revenue: 0,
      profit: 0,
      profit_percent: 0,
      cvr: 0,
    }
  ],
  dataGridCustomization: {
    columnOrder: {},
    columnSizes: {},
    hideColumns: {},
    filters: {},
    forceRefreshCount: 0,
  },
};

const main = createSlice({
  name: 'main',
  initialState,
  reducers: {
    closeRightDrawer: (state) => {
      state.rightDrawer.open = false;
      state.rightDrawer.loading = false;
      state.rightDrawer.children = '';
      state.rightDrawer.title = '';
    },
    openRightDrawer: (state, action: ActionInterface) => {
      state.rightDrawer.open = true;
      state.rightDrawer.title = action.payload.title;
      state.rightDrawer.children = action.payload.children;
      state.rightDrawer.loading = action.payload.loading;
    },
    rightDrawerLoaded: (state) => {
      state.rightDrawer.loading = false;
    },
    rightDrawerLoading: (state) => {
      state.rightDrawer.loading = true;
    },
    closeSnackbar: (state) => {
      state.snackbar.open = false;
    },
    openSnackbar: (
      state: MainState,
      action: { payload: Pick<MainState['snackbar'], 'severity' | 'children'>,
    }) => {
      state.snackbar.key = new Date().getTime();
      state.snackbar.open = true;
      state.snackbar.children = action.payload.children;
      state.snackbar.severity = action.payload.severity;
    },
    closeDialog: (state) => {
      state.dialog.open = false;
    },
    openDialog: (state, action: ActionInterface) => {
      state.dialog.open = true;
      state.dialog.title = action.payload.title;
      state.dialog.children = action.payload.children;
      state.dialog.confirmDialog = action.payload.confirmDialog;
      state.dialog.formDialog = action.payload.formDialog;
      state.dialog.successDialog = action.payload.successDialog;
      state.dialog.warningDialog = action.payload.warningDialog;
      state.dialog.confirmText = action.payload.confirmText;
      state.dialog.cancelText = action.payload.cancelText;
      state.dialog.cancelAction = action.payload.cancelAction;
    },
    closeHelpOverlay: (state) => {
      state.helpOverlay.open = false;
    },
    openHelpOverlay: (state) => {
      state.helpOverlay.open = true;
    },
    setDataGridCustomization: (state, action: ActionInterface) => {
      state.dataGridCustomization = action.payload;
    },
    updateUserSettings: <K extends keyof MainState['userSettings']>(
      state: Draft<MainState>,
      action: PayloadAction<{ key: K; value: MainState['userSettings'][K] }>
    ) => {
      state.userSettings[action.payload.key] = action.payload.value;
    },
    updateTable: (state, action: ActionInterface) => {
      state.dataGridCustomization = action.payload;
    },
    updateMainStore: (state, action = {}) => {
      state[action.payload.key] = action.payload.value;
    }
  }
});

export const {
  closeRightDrawer,
  openRightDrawer,
  rightDrawerLoaded,
  rightDrawerLoading,
  closeSnackbar,
  openSnackbar,
  closeDialog,
  openDialog,
  closeHelpOverlay,
  openHelpOverlay,
  setDataGridCustomization,
  updateUserSettings,
  updateTable,
  updateMainStore
} = main.actions;

export default main.reducer;
