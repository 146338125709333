import { useEffect } from 'react';
import { format } from 'date-fns';
import TaboolaDetails, { modifyOSTargetingValue } from '../../../AddCampaign/Pages/TaboolaDetails';
import OutbrainDetails from '../../../AddCampaign/Pages/OutbrainDetails';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import { isTaboola } from '../../../../../utils/network-utils.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { changeSettingsField } from '../../../../../Redux/Slices/addCampaign.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import '../../../AddCampaign/addCampaign.css';

export default function CampaignSettings() {
	const dispatch = useAppDispatch();
	const { campaignID, network, settingsDialogLoading } = useAppSelector((state) => state.campaignDetails);
	const { userID } = useAppSelector((state) => state.main);
	const { startDate, endDate, weekDay } = useAppSelector((state) => state.filters);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async () => {
		dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
		networkRequest('api/v1/campaign/settings/pull', {
			campaignID,
			network,
			userID,
			weekDay,
			startDate: format(new Date(startDate), 'yyyy-MM-dd'),
			endDate: format(new Date(endDate), 'yyyy-MM-dd'),
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object') {
					setSettings(response);
				}
				dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
			}
		)
		.catch(() => {
			dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
		});
	};

	const plaformTargetingHasAllValues = (values: string[]) => ['1', '2', '3'].every((val: string) => values.includes(val));

	const setSettings = (response) => {
		dispatch(changeSettingsField({ name: 'campaignName', value: response.name || '' }));
		dispatch(changeSettingsField({ name: 'isAgencyCampaign', value: response.isAgencyCampaign || false }));
		dispatch(changeSettingsField({ name: 'isOttoCampaign', value: response.isOtto || false }));
		dispatch(changeSettingsField({ name: 'budget', value: response.budget || 0 }));
		dispatch(changeSettingsField({ name: 'cpc', value: response.cpc || 0 }));
		dispatch(changeSettingsField({ name: 'brandingText', value: response.brandingText || '' }));
		dispatch(changeSettingsField({ name: 'conversionCapThreshold', value: response.conversionCap || '0' }));
		dispatch(changeSettingsField({ name: 'conversionCapTimeframe', value: response.timeframe || '0' }));
		dispatch(changeSettingsField({ name: 'creativeTrafficAllocation', value: response.trafficAllocationMode || '1' }));
		dispatch(changeSettingsField({ name: 'platformTargeting', value: (!response.platformTargeting || plaformTargetingHasAllValues(response.platformTargeting)) ? ['0'] : response.platformTargeting }));
		dispatch(changeSettingsField({ name: 'browserTargeting', value: response.browserTargeting || '0' }));
		dispatch(changeSettingsField({ name: 'osTargeting', value: response.osTargeting ? modifyOSTargetingValue(response.platformTargeting, response.osTargeting) : ['0'] }));
		dispatch(changeSettingsField({ name: 'connectionType', value: response.connectionType || '0' }));
		dispatch(changeSettingsField({ name: 'marketingObjective', value: response.marketingObjective || '1' }));
		dispatch(changeSettingsField({ name: 'bidStrategy', value: response.bidStrategy || '1' }));
		dispatch(changeSettingsField({ name: 'targetCPA', value: response.targetCPA || '1' }));
		dispatch(changeSettingsField({ name: 'usmID', value: response.usmID || '1' }));
		dispatch(changeSettingsField({ name: 'vertical', value: response.vertical || 'auto' }));
		dispatch(changeSettingsField({ name: 'affiliate', value: response.affiliate || '' }));
		dispatch(changeSettingsField({ name: 's1', value: response.s1 || '' }));
	};

	return isTaboola(network) ?
		(settingsDialogLoading ? <FormLoader /> : <TaboolaDetails campaignDetails />)
		: <OutbrainDetails />;
}
