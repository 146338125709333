import {useState} from 'react';
import networkRequest from '../../../../../../utils/networkRequest.ts';
import {openSnackbar, updateMainStore} from '../../../../../../Redux/Slices/main.ts';
import type {AppDispatch} from '../../../../../../Redux/reduxStore.ts';
import {editTableRows} from '../../../../../../utils/tables-utils.tsx';
import {storeData} from "./Sites";
import {Button, CircularProgress} from "@mui/material";
import { changeCampaignDetails } from '../../../../../../Redux/Slices/campaignDetails.ts';

export default function SiteBlockCell(params: never, {
	campaignID,
	network,
	tableRows,
	blockingSubmitting,
}: storeData, dispatch: AppDispatch) {
	const [submitting, setSubmitting] = useState<boolean>(false);

	const updateBlockStatus = () => {
		setSubmitting(true);
		dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: true }));
		const blocked = !params.row.blocked;
		networkRequest('api/v1/campaign/siteBlock/update', {
			campaignID,
			network,
			siteID: params.row.siteID,
			blocked,
		}, 'POST')
			.then((response) => response.json())
			.then((response) => {
					if (response && response.success) {
						dispatch(updateMainStore({
							key: 'tableRows',
							value: editTableRows(tableRows, params.row.siteID, 'blocked', blocked, 'siteID')
						}));
						dispatch(openSnackbar({
							children: `${params.row.siteName} was ${blocked ? 'blocked' : 'unblocked'} successfully.`,
							severity: 'success',
						}));
					} else {
						dispatch(openSnackbar({
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error',
						}));
					}
					setSubmitting(false);
					dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: false }));
				}
			)
			.catch((error) => {
				dispatch(openSnackbar({
					children: error || 'Something went wrong. Please try again.',
					severity: 'error',
				}));
				setSubmitting(false);
				dispatch(changeCampaignDetails({ key: 'blockingSubmitting', value: false }));
			});
	}

	return params.value === undefined ? '' : (
			<Button
				color={params.row.blocked ? 'error' : 'inherit'}
				variant='contained'
				sx={{ width: 120 }}
				onClick={updateBlockStatus}
				disabled={submitting || blockingSubmitting}
			>
				<>
					{submitting ? (
						<CircularProgress size={25} color="inherit"/>
					) : (
						params.row.blocked ? 'Blocked' : 'Unblocked'
					)
					}
				</>
			</Button>
	);
}
