import Input from "../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {changeNonField} from "../../../../../Redux/Slices/addCampaign";
import Select from "../../Fields/Select";
import {IconButton} from "@mui/material";
import Delete from "../../../../../Icons/Add";

import {callToActionOptions} from "./CallToActionOptions.tsx";

export default function Ad({ad, index}: {ad: object, index: number}) {
	const dispatch = useAppDispatch();
	const {ads} = useAppSelector(({addCampaign}) => addCampaign);
	const onChange = (key, value: string) => {
		const newAds = JSON.parse(JSON.stringify(ads));
		newAds[index][key] = value;
		dispatch(changeNonField({key: 'ads', value: newAds}));
	}
	const onDelete = () => {
		const newAds = JSON.parse(JSON.stringify(ads));
		newAds.splice(index, 1);
		dispatch(changeNonField({key: 'ads', value: newAds}));
	}
	return (
		<div key={index} className="display-ad-container">
			<IconButton className="display-ad-delete" id={`display-ad-delete-${index}`} onClick={onDelete}>
				<Delete />
			</IconButton>
			<div className="display-ad-image-container">
				<img
					className="display-ad-image"
					src={(ad.fileType === 'video' && ad.image.split('.').pop() !== 'gif') ? ad.thumbnail : ad.image}
					alt="ad"
				/>
			</div>
			<div className="display-ad-text-container">
				<div className="display-ad-text-edit">
					<Input
						onChange={({target: {value}}) => onChange('headline', value)}
						label="Headline"
						value={ad.headline}
						size={{m: '0px', width: 'calc(100%)'}}
						name={`headline-edit-${index}`}
						multiline
					/>
				</div>
				<div className="display-ad-text-edit">
					<Input
						onChange={({target: {value}}) => onChange('description', value)}
						label="Description"
						value={ad.description}
						size={{m: '0px', width: 'calc(100%)'}}
						name={`description-edit-${index}`}
						multiline
					/>
				</div>
				{ad.fileType !== 'video' &&
					<div className="display-ad-text-edit">
						<Select
							onChange={({target: {value}}) => onChange('callToAction', value)}
							label="Call To Action"
							value={ad.callToAction}
							name={`add-campaign-cta-edit-${index}`}
							options={callToActionOptions}
							size={{m: '0px', width: 'calc(100%)'}}
						/>
					</div>}
			</div>
		</div>
	)
}
