import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function SiteLowestBid(): JSX.Element {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			siteLowestBid,
			campaignAcceptableProfitActive,
			fireAndForgetStatus
		},
		errors: {
			fields: {
				siteLowestBid: siteLowestBidError
			}
		}
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('siteLowestBid', value)}
			label="Lowest Bid"
			value={siteLowestBid}
			numeric
			name="site-lowest-bid"
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			startAdornment={<span>$</span>}
			disabled={!campaignAcceptableProfitActive || fireAndForgetStatus === false}
			error={siteLowestBidError}
			helperText={siteLowestBidError}
		/>
	)
}
