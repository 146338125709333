import {useAppSelector} from "../../../../hooks";
import {useEffect, useState} from "react";


export function NetworkConditionalRender(
	{
		taboolaRender = '',
		outbrainRender = '',
		defaultRender = '',
	} : {
		taboolaRender?: JSX.Element,
		outbrainRender?: JSX.Element,
		defaultRender?: JSX.Element,
	}
) {
	const { network } = useAppSelector((state) => state.campaignDetails);
	const [render, setRender] = useState<JSX.Element>(defaultRender);

	useEffect(() => {
		if (network === 1) {
			setRender(taboolaRender);
		} else if (network === 2) {
			setRender(outbrainRender);
		}
	}, [network])

	return render;
}


export const networkConditionalFormatArray = (
	{
		network,
		taboolaArray = [],
		outbrainArray = [],
		defaultArray = [],
	} : {
		network: number,
		taboolaArray?: Array<string>,
		outbrainArray?: Array<string>,
		defaultArray?: Array<string>,
	}
) => {
	let formattedArray: Array<string> = defaultArray;
	if (network === 1) {
		formattedArray = [...formattedArray, ...taboolaArray];
	} else if (network === 2) {
		formattedArray = [...formattedArray, ...outbrainArray];
	}
	return formattedArray;
}


export const networkConditionalFormatObject = (
	{
		network,
		taboolaObject = {},
		outbrainObject = {},
		defaultObject = {},
	} : {
		network: number,
		taboolaObject?: object,
		outbrainObject?: object,
		defaultObject?: object,
	}
) => {
	let formattedObject: object = defaultObject;
	if (network === 1) {
		formattedObject = {...formattedObject, ...taboolaObject};
	} else if (network === 2) {
		formattedObject = {...formattedObject, ...outbrainObject};
	}
	return formattedObject;
}