import Input from "../../../Fields/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {changeFireAndForgetField} from '../../../../../../Redux/Slices/addCampaign';

export default function SiteAcceptableProfit() {
	const dispatch = useAppDispatch();
	const {
		fireAndForget: {
			siteAcceptableProfit,
			campaignAcceptableProfitActive,
			fireAndForgetStatus,
		},
		errors: {
			fields: {
				siteAcceptableProfit: siteAcceptableProfitError
			}
		},
	} = useAppSelector((state) => state.addCampaign);

	const updateFireAndForget = (name, value) => {
		dispatch(changeFireAndForgetField({name, value}));
	}

	return (
		<Input
			onChange={({target: {value}}) => updateFireAndForget('siteAcceptableProfit', value)}
			label="Site Acceptable Profit"
			value={siteAcceptableProfit}
			name="site-acceptable-profit"
			type="number"
			size={{m: '12px', width: 'calc(50% - 24px)'}}
			endAdornment={<span>%</span>}
			disabled={!campaignAcceptableProfitActive || fireAndForgetStatus === false}
			error={siteAcceptableProfitError}
			helperText={siteAcceptableProfitError}
		/>
	)
}
