import {ClientOnly} from "../utils/ClientOnly";

export default function AddTaboolaCampaign() {
	return (
		<ClientOnly
			component={() => import("../Components/Pages/AddCampaign")}
		/>
	)
}



