import NoImage from "../../../../../Icons/NoImage";
import {useAppSelector} from "../../../../../hooks";
import Ad from "./Ad";

export default function DisplayAdsSection(): JSX.Element {
	const {ads} = useAppSelector(({addCampaign}) => addCampaign);
	return (
		<div className="add-campaign-display-ads-container">
			<div className="add-ads-titles">
				Ads
				<div className="hr-bar" />
			</div>
			{ads.length === 0 ? (
				<div className="display-no-image">
					<NoImage className="add-campaign-no-image-icon" />
					<div className="display-no-image-text">
						No Ads To Display
					</div>
				</div>
			) : (
				<div className="display-ads-grid">
					{ads.map((ad, index) => <Ad key={ad.uuid} ad={ad} index={index} />)}
				</div>
			)}
		</div>
	);
}
