import networkRequest from "../../../../utils/networkRequest";
import {openSnackbar} from "../../../../Redux/Slices/main";

export async function updateEstimatedSpends(value: boolean, dispatch: any, campaignID: string, network: number) {
	dispatch({ type: 'campaignDetails/toggleEstimatedSpends' });
	return networkRequest('api/v1/campaign/estimatedSpends/update', { campaignID, estimatedSpends: value, network })
		.then((response) => response.json())
		.then((response) => {
			if (response && response.status) {
				if (!response.success) {
					dispatch({ type: 'campaignDetails/toggleEstimatedSpends' });
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error',
						},
					));
				}
			}
		});
}
