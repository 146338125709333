import isUrlHttp from "is-url-http";
import {ValidateStepProps} from "../StepsValidation.tsx";

const getParamFromString = (search: string, link: string): string => {
	if (!link.includes(search)) {
		return '';
	}
	const param = link.split('&').find((str) => str.includes(search));
	if (param && param.includes('=')) {
		return param.replace(search, '');
	}
	return '';
}
const findParamErrors = (isOtto: boolean, link: string, ottoS1: string): string  => {
	const params = link.includes('?') ? link.split('?')[1] : '';
	if (!params || !isOtto) {
		return '';
	}
	const maximusParam = getParamFromString('click=', params);
	const s1Param = getParamFromString('s1=', params);
	if (maximusParam && maximusParam !== 'maximus_new') {
		return "For OTTO campaigns, please set the 'click' parameter to 'maximus_new'";
	}
	if (s1Param && ottoS1 && s1Param.toLowerCase() !== ottoS1.toLowerCase()) {
		return `Looks like your S1 value does not match the S1 in Campaign Settings. Please set your S1 to ${ottoS1} in this link and try again.`;
	}
	return '';
}

export const validateLinks = (links: object): ValidateStepProps => {
	const errors = {fields: {}};
	if (!links.link || !isUrlHttp(links.link)) {
		errors.fields.link = `${links.maximusLinks ? 'Review Page' : 'Campaign Link'} URL is invalid.`;
	}
	if (links.maximusLinks) {
		if (links.advancedTracking) {
			for (const key in links.landingPageLinks) {
				if (!links.landingPageLinks[key].url || !isUrlHttp(links.landingPageLinks[key].url)) {
					errors.fields[`landingPageLinks${key}`] = `Landing page URL is invalid.`;
				}
				if (!links.landingPageLinks[key].weight || Number(links.landingPageLinks[key].weight) <= -1) {
					errors.fields[`landingPageLinks${key}`] = `Landing page weight should be at least 0.`;
				}
				if (!links.landingPageLinks[key].weight || Number(links.landingPageLinks[key].weight) > 100) {
					errors.fields[`landingPageLinks${key}`] = `Landing page weight should be less than or equal to 100.`;
				}
				const paramErrors = findParamErrors(links.campaignSettings.isOttoCampaign, links.landingPageLinks[key].url, links.campaignSettings.s1);
				if (paramErrors) {
					errors.fields[`landingPageLinks${key}`] = paramErrors;
				}
			}
		}
		for (const key in links.presellLinks) {
			if (!links.presellLinks[key].url || !isUrlHttp(links.presellLinks[key].url)) {
				errors.fields[`presellLinks${key}`] = `Presell page URL is invalid.`;
			}
			const paramErrors = findParamErrors(links.campaignSettings.isOttoCampaign, links.presellLinks[key].url, links.campaignSettings.s1);
			if (paramErrors) {
				errors.fields[`presellLinks${key}`] = paramErrors;
			}
			if (!links.presellLinks[key].weight || isNaN(links.presellLinks[key].weight)) {
				errors.fields[`presellLinks${key}`] = `Presell page weight should be a number.`;
			} else {
				if (!links.presellLinks[key].weight || Number(links.presellLinks[key].weight) <= -1) {
					errors.fields[`presellLinks${key}`] = `Presell page weight should be at least 0.`;
				}
				if (!links.presellLinks[key].weight || Number(links.presellLinks[key].weight) > 100) {
					errors.fields[`presellLinks${key}`] = `Presell page weight should be less than or equal to 100.`;
				}
			}
		}
	}
	return errors;
}