import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';
import { modifyAdsForParams } from '../../../AddCampaign/Components/SubmitCampaign.tsx';
import { resetAddCampaign } from '../../../../../Redux/Slices/addCampaign.ts';

export const useSubmitAds = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network } = useAppSelector((state) => state.campaignDetails);
	const ads = useAppSelector((state) => state.addCampaign.ads);

	return async () =>  {
		await networkRequest('api/v1/campaign/ad/create', {
			campaignID,
			network,
			ads: modifyAdsForParams(ads),
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object') {
					dispatch({ type: 'campaignDetails/closeDialog' });
					dispatch(resetAddCampaign());
				} else {
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again',
							severity: 'error'
						},
					));
				}
			}
		);
	}
}
