import {useCallback, useEffect, useState} from 'react';
import lodash from 'lodash';
import {format} from 'date-fns';
import {DataGridPremiumProps, GridCellModesModel, GridColDef, GridEventListener, useGridApiRef} from '@mui/x-data-grid-premium';
import {useCancellableNetworkRequest} from '../../utils/networkRequest.ts';
import {useAppDispatch, useAppSelector, usePrevious} from '../../hooks.ts';
import DataGrid from './DataGrid.tsx';
import {updateMainStore} from '../../Redux/Slices/main';
import {refreshAll} from '../../Redux/Slices/filters.ts';
import {
  columnReorderHelper,
  columnVisibilityHelper,
  onColumnOrderChange,
  onColumnVisibilityChange
} from "./Helpers/ColumnReorderHelper";
import {estimatedFieldsMap} from "./constants.ts";

const {isEqual} = lodash;

const formatRows = (rows: Array<object>) => rows.map((row: object, id: number) => ({...row, id: id + 1}));

interface ReportTableProps extends Pick<DataGridPremiumProps, 'getRowHeight'> {
	columns: GridColDef[],
	tableName: string,
	endpoint: string,
	filters?: string[],
	notCampaignDetails?: boolean,
	notUseDefaultFilters?: boolean,
	additionalRequestParams?: object,
	aggregationModel?: object,
	sortModel?: object[],
	rowHeight?: number,
	cellModesModel?: object,
	columnVisibility?: object,
	onCellModesModelChange?: (model: GridCellModesModel) => void,
	processRowUpdate?: (updatedRow: object, originalRow: object) => Promise<unknown>,
}

export default function ReportTable(props: ReportTableProps) {
	const {
		columns,
    tableName,
    endpoint,
    rowHeight,
		filters = [],
    notCampaignDetails = false,
    notUseDefaultFilters = false,
		additionalRequestParams = {},
    aggregationModel = {},
    sortModel = [],
    cellModesModel = {},
		getRowHeight,
		onCellModesModelChange,
    processRowUpdate,
	} = props;
	const filterStore = useAppSelector((state) => state.filters);
	const main = useAppSelector((state) => state.main);
	const {
		campaignID,
		network,
		estimatedSpends
	} = useAppSelector((state) => state.campaignDetails);
	const [loading, setLoading] = useState<boolean>(true);
	const {tableRows: rows} = main;
	const dispatch = useAppDispatch();
	const apiRef = useGridApiRef();

	const setFormattedFilters = (): object => {
		const formattedFilters: object = {};
		const defaultFilters = ['startDate', 'endDate', 'dateRange', 'weekDay'];
		let filtersList = [...filters];
		if (!notUseDefaultFilters) {
			filtersList = [
				...filtersList,
				...defaultFilters,
			];
		}
		Object.keys(filterStore).forEach((filter) => {
			if (filtersList.includes(filter) && filterStore[filter] !== undefined) {
				if (filter === 'startDate' || filter === 'endDate') {
					formattedFilters[filter] = format(new Date(filterStore[filter]), 'yyyy-MM-dd');
				} else {
					formattedFilters[filter] = filterStore[filter];
				}
			}
		});
		if (!notCampaignDetails) {
			formattedFilters.campaignID = campaignID;
			formattedFilters.network = network;
		}
		return {
			...formattedFilters,
			...additionalRequestParams,
		};
	};

	const previousFilters = usePrevious(setFormattedFilters());
	const previousEndpoint = usePrevious(endpoint);

	const cancellableRequest = useCancellableNetworkRequest();

	const getData = async () => {
		const formattedFilters = setFormattedFilters();
		setLoading(true);
		dispatch(refreshAll(false));
		dispatch(updateMainStore({key: 'tableRows', value: []}));
		cancellableRequest(endpoint, {
			...formattedFilters,
		}, 'POST')
			.then((response) => response?.json())
			.then((response) => {
				if (!(response && typeof response === 'object')) return;
				dispatch(updateMainStore({ key: 'tableRows', value: formatRows(response) }));
			})
			.catch(() => {
				dispatch(updateMainStore({key: 'tableRows', value: []}));
			})
			.finally(() => {
				setLoading(false);
			});
	};

  const modifyColumns = (columns: GridColDef[]): GridColDef[] => {
    if (estimatedSpends && !notCampaignDetails && tableName !== 'States') {
      const columnsNames = Object.keys(estimatedFieldsMap);
      return columns.map(column => ({
        ...column,
        field: columnsNames.includes(column.field) ? estimatedFieldsMap[column.field] : column.field,
      }));
    }
    return columns;
  };

	useEffect(() => {
		const columnHeaderDragEndUnsub = apiRef.current.subscribeEvent(
			'columnHeaderDragEnd', () => {
				onColumnOrderChange(
					modifyColumns(apiRef?.current?.getAllColumns() || []),
					tableName
				)
			});

		const columnVisibilityModelChangeUnsub = apiRef.current.subscribeEvent('columnVisibilityModelChange', () => {
			return onColumnVisibilityChange(
				modifyColumns(apiRef?.current?.getVisibleColumns() || []),
				tableName,
				modifyColumns(columns)
			);
		});

		const currentFilters = JSON.parse(localStorage.getItem(`${tableName}_filterModel`) || 'false')
		if (currentFilters) {
			apiRef.current.restoreState({ filter: { filterModel: currentFilters } })
		}
		const filterModelChangeUnsub = apiRef.current.subscribeEvent('filterModelChange', (filters, details) => {
			if ((details as any).reason === 'removeAllFilterItems') {
				localStorage.removeItem(`${tableName}_filterModel`)
			} else {
				localStorage.setItem(`${tableName}_filterModel`, JSON.stringify(filters))
			}
		})

		return () => {
			columnHeaderDragEndUnsub()
			columnVisibilityModelChangeUnsub()
			filterModelChangeUnsub()
		}
	}, [apiRef, columns, tableName]);

	useEffect(() => {
		async function makeRequest() {
			if (!isEqual(setFormattedFilters(), previousFilters)
				|| filterStore.refreshAllData
				|| (previousEndpoint !== endpoint)
			) {
				return getData();

			}
			return true;
		}

		makeRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFormattedFilters, previousFilters, filterStore.refreshAllData, endpoint, previousEndpoint]);

	const handleCellEditStop = useCallback<GridEventListener<'cellEditStop'>>(
		(params, event) => {
			event.defaultMuiPrevented = true;
		},
		[],
	);

	const dateRangeTitle = `${format(new Date(filterStore.startDate), 'yyyy-MM-dd')} - ${format(new Date(filterStore.endDate), 'yyyy-MM-dd')}`;
	const defaultSortModel = (estimatedSpends && tableName !== 'States') ? [{
		field: 'estimatedSpend',
		sort: 'desc'
	}] : [{field: 'spend', sort: 'desc'}];

	return (
		<DataGrid
			apiRef={apiRef}
			rows={rows}
			columns={modifyColumns(columnReorderHelper(modifyColumns(columns), tableName, estimatedSpends))}
			pageSize={5}
			rowsPerPageOptions={[5]}
			height={700}
			tableName={tableName}
			dateRangeTitle={dateRangeTitle}
			rowHeight={rowHeight || null}
			getRowHeight={getRowHeight}
			loading={loading}
			aggregationModel={aggregationModel}
			sortModel={!sortModel.length ? defaultSortModel : sortModel}
			cellModesModel={cellModesModel}
			onCellEditStop={handleCellEditStop}
			onCellModesModelChange={onCellModesModelChange}
			processRowUpdate={processRowUpdate}
			columnVisibilityModel={columnVisibilityHelper(modifyColumns(columns), tableName, estimatedSpends)}
		/>
	)
}
