import SnackBar from "./SnackBar";
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://5fb3f22aa0362530ed2c102adc72d8c4@o4505160163131392.ingest.sentry.io/4506304058163200",
	integrations: [
		Sentry.browserTracingIntegration({}),
		Sentry.replayIntegration(),
	],
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function UIElements() {
	return (
		<>
			<SnackBar />
		</>
	)
}