import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';

const columns = [
	{
		field: 'platform', headerName: 'Platform', width: 140,
	},
		...commonColumns(
		[
			'impressions',
			'clicks',
			'conversions',
			'epc',
			'average_cpc',
			'ctr',
			'cvr',
			'cpa',
			'roas',
			'revenue',
			'spend',
			'profit',
			'profit_percent',
		],
	),
];

export default function Platforms() {
	return (
		<div className="campaign-details-report">
			<ReportTable
				columns={columns}
				tableName="Platforms"
				endpoint="api/v1/campaign/reporting/platform"
				aggregationModel={{
					impressions: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					average_cpc: 'avg',
					ctr: 'avg',
					epc: 'avg',
					cpa: 'avg',
					cvr: 'avg',
					roas: 'avg',
					spend: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					revenue: 'sum',
					platform: 'blankColumn',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
				}}
			/>
		</div>
	);
}
