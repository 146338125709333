import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { CurrencyComparator } from '../../../../../utils/tables-utils.tsx';
import { formatCurrency } from '../../../../../utils/formatNumbers.ts';

const columns = [
	{
		field: 'clickDate', headerName: 'Click Date', width: 190, type: 'timestamp'
	},
	{
		field: 'conversionDate', headerName: 'Conversion Date', width: 190, type: 'timestamp'
	},
	{
		field: 'siteName', headerName: 'Site Name', width: 200, renderCell: (params: never) => ( <a target="_blank" className="manage-name-link" href={`https://${params.value}`}>{params.value}</a> ),
	},
	...commonColumns(
		[
			'cpc',
		],
	),
	{
		field: 'payout',
		headerName: 'Payout',
		width: 100,
		type: 'number',
		sortComparator: CurrencyComparator,
		renderCell: (params: never) => formatCurrency(params.value),
	},
	{
		field: 'ip', headerName: 'IP', width: 160,
	},
	{
		field: 'clickID', headerName: 'Click ID', width: 350,
	},
	{
		field: 'userAgent', headerName: 'User Agent', width: 500,
	},
];

export default function Conversions() {
	return (
		<div className="campaign-details-report">
			<ReportTable
				columns={columns}
				tableName="Conversions"
				endpoint="api/v1/campaign/reporting/conversions"
			/>
		</div>
	);
}
